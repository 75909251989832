import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { UserContext } from 'context/userContext';

import HomeNavigator from './HomeNavigator';

import '../styles/styles.scss';

function MainNavigator() {
    const [isLogin, setIsLogin] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [user, setUser] = useState(null);
    const [notifs, setNotifs] = useState(null);
    const [isFetchingNotifs, setIsFetchingNotifs] = useState(true);
    const [numberNotifs, setNumbetNotifs] = useState(0);

    const contextObj = useMemo(
        () => ({
            isLogin,
            setIsLogin,
            user,
            setUser,
            notifs,
            setNotifs,
            isFetchingNotifs,
            setIsFetchingNotifs,
            numberNotifs,
            setNumbetNotifs,
            isFetching,
            setIsFetching,
        }),
        [isLogin, user, notifs, isFetchingNotifs, numberNotifs, isFetching]
    );

    return (
        <BrowserRouter>
            <UserContext.Provider value={contextObj}>
                <HomeNavigator />
            </UserContext.Provider>
        </BrowserRouter>
    );
}

export default MainNavigator;
