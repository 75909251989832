import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Button,
    CheckBox,
    EmptyData,
    notify,
    Table,
    TableContainer,
    TableLoader,
    Th,
} from '@sobrus-com/sobrus-design-system-v2';

import { axiosGet, axiosPatch, axiosPut } from '../../../api/API';
import BackwardTitle from '../../../components/backwardTitle/BackwardTitle';

function RolesEdit() {
    const [isFetchingRoles, setIsFetchingRoles] = useState(true);
    const [actions, setActions] = useState(null);
    const [role, setRole] = useState(null);
    const [disableBtn, setDisableBtn] = useState(false);
    const [activeActions, setActiveActions] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();
    const { t } = useTranslation();

    // const { pageNumber, pageChange, setPageNumber } = usePagination(setIsFetchingRoles);

    useEffect(() => {
        if (isFetchingRoles) {
            axiosGet(`actions`).then((res) => {
                setActions(res?.data?.actions);
                setIsFetchingRoles(false);
            });
        }
    }, [isFetchingRoles]);

    useEffect(() => {
        axiosGet(`roles/${id}`).then((res) => {
            if (res?.data) {
                setActiveActions(res?.data?.allowedActions);
                setRole(res?.data?.role);
            }
            // console.log(arrActives.current);
        });
    }, []);

    const addRoles = () => {
        // console.log(arrActives.current);
        if (!disableBtn) {
            setDisableBtn(true);
            axiosPatch(`roles/${id}`, { allowedActions: activeActions.map((ele) => ele.id) }).then((res) => {
                if (res?.data) {
                    notify({
                        position: 'top-center',
                        type: 'success',
                        msg: 'les méthodes ont été affectées avec succès',
                        delay: 5000,
                    });
                    navigate('/settings/roles');
                } else {
                    notify({
                        position: 'top-center',
                        type: 'danger',
                        msg: "les méthodes n'ont pas été affectées",
                        delay: 5000,
                    });
                    setDisableBtn(false);
                }
            });
        }
    };

    return (
        <TableContainer>
            <div className="labs-settings-btns">
                <Button plus={false} onClick={addRoles}>
                    {t('Save')}
                </Button>
            </div>
            <BackwardTitle title={`${t('Role')} : ${role?.nom}`} to="/settings/roles" />
            {/* <TableHeader>
                <TableHeaderTitle>Listes d'autorisations</TableHeaderTitle>
            </TableHeader> */}
            <Table>
                <thead>
                    <tr>
                        <Th hideOrder>{t('Permission lists')}</Th>
                        <Th hideOrder>Action</Th>
                        <Th
                            // style={{
                            //     display: 'flex',
                            //     alignItems: 'center',
                            //     margin: '0 auto',
                            //     width: 'fit-content',
                            //     marginTop: '10px',
                            // }}
                            hideOrder
                        >
                            <CheckBox
                                id="allIds"
                                // checked={activeRoles?.find((ac) => ac.id === ele.id)}
                                checked={actions?.length === activeActions?.length}
                                onChange={(e) => {
                                    e.target.checked ? setActiveActions([...actions]) : setActiveActions([]);
                                }}
                                label={t('Activate All')}
                            />
                        </Th>
                        <th aria-label="empty" width="75px" />
                        {/* <Th>Action</Th> */}
                    </tr>
                </thead>
                {isFetchingRoles && (
                    <TableLoader loading columns={[{ type: 'extra-small' }, { type: 'large' }, { type: 'dot' }]} />
                )}
                <tbody>
                    {!isFetchingRoles &&
                        actions &&
                        actions.map((ele, indx) => {
                            return (
                                <tr
                                    key={`${ele.id}gammesprod`}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setActiveActions((prev) =>
                                            prev.some((accrole) => accrole.id === ele.id)
                                                ? prev.filter((accrole) => accrole.id !== ele.id)
                                                : [...activeActions, ele]
                                        );
                                    }}
                                >
                                    <td>{ele.label}</td>
                                    <td>{ele.name}</td>
                                    <td
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            margin: '0 auto',
                                            marginTop: '10px',
                                            width: '20px',
                                        }}
                                    >
                                        <CheckBox
                                            id={indx}
                                            checked={activeActions?.some((ar) => ar.id === ele.id) || null}
                                        />
                                        {/* <input type="checkbox" id={indx}></input> */}
                                    </td>
                                    <td width="75px" />
                                </tr>
                            );
                        })}

                    {/* {roles?.length > 0 && (
                        <tr>
                            <td colSpan={7}>
                                <div className="sob-table-pagination">
                                    <TablePreviousPage onClick={() => pageChange("prev")} disabled={pageNumber <= 1} />
                                    <TablePage>{pageNumber}</TablePage>
                                    <TableNextPage onClick={() => pageChange("next")} disabled={roles?.length < 20} />
                                </div>
                            </td>
                        </tr>
                    )} */}
                </tbody>
            </Table>
            {!isFetchingRoles && actions?.length < 1 && <EmptyData title={t('No results match your search')} />}
        </TableContainer>
    );
}

export default RolesEdit;
