import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb } from '@sobrus-com/sobrus-design-system-v2';

import './BackwardTitle.scss';

function BackwardTitle({ title, subTitle, to, isBackward = false, onClick, ...att }) {
    const navigate = useNavigate();

    return (
        <Breadcrumb
            title={title}
            subTitle={subTitle}
            isBackward={isBackward}
            onClick={onClick || (isBackward ? () => navigate(to, { replace: true }) : null)}
            {...att}
        />
    );
}

export default BackwardTitle;
