import React from 'react';
import { Button } from '@sobrus-com/sobrus-design-system-v2';
import { AUTH_URL } from 'env';

function Failed() {
    return (
        <section className="failed-container">
            <svg width="235" height="231" viewBox="0 0 235 231" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M58.7932 37.5523C58.7932 37.5523 94.172 64.2801 147.957 14.304C195.723 -30.0785 234.725 39.9471 234.998 73.4812C235.352 116.921 187.232 151.679 210.584 180.211C233.937 208.743 164.273 255.859 126.728 215.083C80.0225 164.36 67.3696 205.573 40.748 205.573C21.6413 205.573 -17.5864 158.31 8.90366 123.148C31.1948 93.559 19.0376 83.7392 13.1503 73.4812C4.65782 58.6869 24.8259 18.5312 58.7932 37.5523Z"
                    fill="#FBE9EA"
                />
                <path
                    d="M165.612 149.925H68.5224C63.8156 149.925 60 153.74 60 158.447V169.237C60 173.944 63.8156 177.759 68.5224 177.759H165.612C170.319 177.759 174.135 173.944 174.135 169.237V158.447C174.135 153.74 170.319 149.925 165.612 149.925Z"
                    fill="white"
                />
                <path
                    d="M165.612 111.978H68.5224C63.8156 111.978 60 115.793 60 120.5V131.29C60 135.997 63.8156 139.812 68.5224 139.812H165.612C170.319 139.812 174.135 135.997 174.135 131.29V120.5C174.135 115.793 170.319 111.978 165.612 111.978Z"
                    fill="white"
                />
                <path
                    d="M73.8175 160.727C72.9285 160.727 72.1774 161.025 71.5662 161.623C70.955 162.221 70.6484 162.957 70.6484 163.832C70.6484 164.819 70.9646 165.585 71.5969 166.135C72.2291 166.683 72.9687 166.959 73.8156 166.959C74.6625 166.959 75.358 166.681 75.9826 166.126C76.6072 165.57 76.9215 164.806 76.9215 163.834C76.9215 162.959 76.6226 162.223 76.0248 161.625C75.427 161.027 74.6912 160.729 73.8156 160.729L73.8175 160.727Z"
                    fill="#DF4751"
                />
                <path
                    d="M83.8917 160.727C83.0027 160.727 82.2516 161.025 81.6404 161.623C81.0292 162.221 80.7227 162.957 80.7227 163.832C80.7227 164.819 81.0388 165.585 81.6711 166.135C82.3034 166.683 83.0429 166.959 83.8898 166.959C84.7367 166.959 85.4322 166.681 86.0568 166.126C86.6815 165.57 86.9957 164.806 86.9957 163.834C86.9957 162.959 86.6968 162.223 86.099 161.625C85.5012 161.027 84.7654 160.729 83.8898 160.729L83.8917 160.727Z"
                    fill="#DF4751"
                />
                <path
                    d="M93.9269 160.727C93.0379 160.727 92.2868 161.025 91.6756 161.623C91.0644 162.221 90.7578 162.957 90.7578 163.832C90.7578 164.819 91.074 165.585 91.7062 166.135C92.3385 166.683 93.0781 166.959 93.925 166.959C94.7719 166.959 95.4674 166.681 96.092 166.126C96.7166 165.57 97.0308 164.806 97.0308 163.834C97.0308 162.959 96.7319 162.223 96.1341 161.625C95.5364 161.027 94.8006 160.729 93.925 160.729L93.9269 160.727Z"
                    fill="#DF4751"
                />
                <path
                    d="M104.888 160.727C103.999 160.727 103.248 161.025 102.637 161.623C102.025 162.221 101.719 162.957 101.719 163.832C101.719 164.819 102.035 165.585 102.667 166.135C103.299 166.683 104.039 166.959 104.886 166.959C105.733 166.959 106.428 166.681 107.053 166.126C107.678 165.57 107.992 164.806 107.992 163.834C107.992 162.959 107.693 162.223 107.095 161.625C106.497 161.027 105.762 160.729 104.886 160.729L104.888 160.727Z"
                    fill="#DF4751"
                />
                <path
                    d="M114.966 160.727C114.077 160.727 113.326 161.025 112.715 161.623C112.103 162.221 111.797 162.957 111.797 163.832C111.797 164.819 112.113 165.585 112.745 166.135C113.378 166.683 114.117 166.959 114.964 166.959C115.811 166.959 116.506 166.681 117.131 166.126C117.756 165.57 118.07 164.806 118.07 163.834C118.07 162.959 117.771 162.223 117.173 161.625C116.575 161.027 115.84 160.729 114.964 160.729L114.966 160.727Z"
                    fill="#FBE9EA"
                />
                <path
                    d="M124.999 160.727C124.11 160.727 123.359 161.025 122.748 161.623C122.137 162.221 121.83 162.957 121.83 163.832C121.83 164.819 122.146 165.585 122.779 166.135C123.411 166.683 124.15 166.959 124.997 166.959C125.844 166.959 126.54 166.681 127.164 166.126C127.789 165.57 128.103 164.806 128.103 163.834C128.103 162.959 127.804 162.223 127.206 161.625C126.609 161.027 125.873 160.729 124.997 160.729L124.999 160.727Z"
                    fill="#FBE9EA"
                />
                <path
                    d="M152.265 122.989H73.555C71.9487 122.989 70.6465 124.29 70.6465 125.896C70.6465 127.502 71.9487 128.804 73.555 128.804H152.265C153.871 128.804 155.173 127.502 155.173 125.896C155.173 124.29 153.871 122.989 152.265 122.989Z"
                    fill="#DF4751"
                />
                <path
                    d="M116.794 53.5309C131.908 53.5309 144.937 62.7369 150.911 76.0142C156.963 68.5038 160.589 58.9549 160.589 48.5591C160.589 24.3707 140.98 4.76508 116.794 4.76508C92.6085 4.76508 73 24.3707 73 48.5572C73 58.953 76.6251 68.5019 82.6778 76.0123C88.6519 62.7369 101.681 53.529 116.794 53.529V53.5309Z"
                    fill="#DF4751"
                />
                <path
                    d="M116.794 92.3494C130.584 92.3494 142.883 85.9751 150.911 76.0123C144.937 62.7369 131.908 53.529 116.794 53.529C101.681 53.529 88.6538 62.735 82.6777 76.0123C90.7058 85.9732 103.005 92.3494 116.794 92.3494Z"
                    fill="white"
                />
                <path
                    d="M129.433 44.9066C136.413 37.9272 136.413 26.6115 129.433 19.6322C122.453 12.6529 111.137 12.6529 104.157 19.6322C97.1777 26.6115 97.1777 37.9272 104.157 44.9066C111.137 51.8859 122.453 51.8859 129.433 44.9066Z"
                    fill="white"
                />
            </svg>
            <h1>Le compte utilisateur semble introuvable ou désactivé</h1>
            <div>Retour à la page de connexion</div>
            {/* <h1>Code erreur : Code 4</h1>
            <h1>Il n&apos;y a pas de compte lié à cette adresse e-mail</h1>
            <div>Retour à la page de connexion</div> */}
            <a href={AUTH_URL} rel="noopener noreferrer">
                <Button>Changer de compte</Button>
            </a>
        </section>
    );
}

export default Failed;
