import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation, withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
// import { SupportBtn } from '@sobrus-com/sobrus-design-system-v2';
import { Card, CustomButton, SupportBtn } from '@sobrus-com/sobrus-design-system-v2';
import { Posts } from '@sobrus-com/sobrus-posts-package';
import BackwardTitle from 'components/backwardTitle/BackwardTitle';
// import BackwardTitle from 'components/backwardTitle/BackwardTitle';
import FastActionCard from 'components/fastActionCard/FastActionCard';
import { format } from 'date-fns';
import { API_URL2 } from 'env';
import useTitle from 'utils/useTitle';

import { axiosGet, axiosPost } from '../../api/API';
import { UserContext } from '../../context/userContext';

import EventAccordion from './EventAccordion';

function TableauDeBord() {
    const [posts, setPosts] = useState(null);
    const [totals, setTotals] = useState(null);
    const [eventsComing, setEventsComing] = useState(null);
    const [eventsReported, setEventsReported] = useState(null);
    const [eventsVisited, setEventsVisited] = useState(null);
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    // useEffect(() => {
    //     if (!posts) {
    //         axiosGet("posts?targetId=5").then((res) => {
    //             setPosts(res?.data?.posts);
    //         });
    //     }
    // }, []);
    useTitle(t('Home'));

    useEffect(() => {
        if (!totals) {
            axiosGet(`dashboard/statistics`).then((res) => {
                setTotals(res?.data);
            });
        }
    }, []);

    useEffect(() => {
        if (!eventsReported) {
            const searchTerm = {
                orderBy: 'startDate',
                order: 'ASC',
                startDate: format(new Date(), 'yyyy-MM-dd'),
                status: 'Reporté',
                administratorId: user?.id,
            };
            axiosGet('events', searchTerm).then((res) => {
                setEventsReported(res?.data?.events);
            });
        }
        if (!eventsComing) {
            const searchTerm = {
                orderBy: 'startDate',
                order: 'ASC',
                startDate: format(new Date(), 'yyyy-MM-dd'),
                status: 'Planifié',
                administratorId: user?.id,
            };
            axiosGet('events', searchTerm).then((res) => {
                setEventsComing(res?.data?.events);
            });
        }
        if (!eventsVisited) {
            const searchTerm = {
                orderBy: 'startDate',
                order: 'ASC',
                startDate: format(new Date(), 'yyyy-MM-dd'),
                status: 'Visité',
                administratorId: user?.id,
            };
            axiosGet('events', searchTerm).then((res) => {
                setEventsVisited(res?.data?.events);
            });
        }
    }, []);

    const postUserData = {
        fullName: `${user?.firstName} ${user?.lastName}`,
        userId: user?.id,
    };

    return (
        <section data-aos="fade" className="dashbord-container labs-container fade">
            <div className="dashbord-header">
                <BackwardTitle title={t('News feed')} subTitle={t('Home')} />
                {/* <Breadcrumb title="zeze" subTitle="zeze" /> */}
                {/* <Button>dsd</Button> */}
                {/* <SupportBtn
                    position="bottom right"
                    data={{
                        clientRef: 'ma-3948-35523-rr',
                        email: 'support@sobrus.com',
                        phoneNumber: '05 30 500 500',
                        showEmail: true,
                    }}
                /> */}
                <SupportBtn
                    email="support@sobrus.com"
                    size="md"
                    workHours={[
                        {
                            afternoon: '14:00 à 18:00',
                            label: 'Du lundi au Vendredi',
                            morning: '09:00 à 13:00',
                        },
                        {
                            label: 'Le Samedi',
                            morning: '09:00 à 13:00',
                        },
                    ]}
                />
            </div>
            <div className="dashbord-body">
                <div className="dashboard-leftSide">
                    <Card
                        IconButton={
                            <svg
                                // style={{ display: 'block' }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="42"
                                height="42"
                                viewBox="0 0 42.62 42.62"
                            >
                                <g id="fastActions" transform="translate(-19.448 -10.654)">
                                    <rect
                                        id="Rectangle_7"
                                        data-name="Rectangle 7"
                                        width="42.62"
                                        height="42.62"
                                        rx="13"
                                        transform="translate(19.448 10.654)"
                                        fill="rgba(85,172,237,0.08)"
                                    />
                                    <path
                                        id="shortcut-script-app"
                                        d="M26.47,18.861l-3.1-1.4,3.1-1.4a1.62,1.62,0,0,0,0-2.926L18.7,9.625l2.024-2.2a1.775,1.775,0,0,0,0-2.391L16.388.338a1.085,1.085,0,0,0-1.867.735l0,2.656a10.459,10.459,0,0,0-4.9,1.609.4.4,0,0,0,.445.66,9.9,9.9,0,0,1,4.855-1.491.4.4,0,0,0,.4-.4l0-3.036A.281.281,0,0,1,15.8.878l4.331,4.693a.974.974,0,0,1,0,1.313l-4.321,4.7a.312.312,0,0,1-.519-.193c0-.2,0-3.07,0-3.192a.4.4,0,0,0-.415-.393,6.267,6.267,0,0,0-2.21.586A4.658,4.658,0,0,0,9.964,12.03a6.17,6.17,0,0,0,1.665,5.257,5.325,5.325,0,0,1-2.881-1.681,5.876,5.876,0,0,1-1.49-3.864,6.966,6.966,0,0,1,1.357-4.4.4.4,0,0,0-.628-.49,7.356,7.356,0,0,0-1.476,3.874L1.179,13.134a1.62,1.62,0,0,0,0,2.926l3.1,1.4-3.1,1.4a1.62,1.62,0,0,0,0,2.926L12.43,26.866a3.391,3.391,0,0,0,2.789,0l4.657-2.1a.4.4,0,0,0-.328-.726l-4.657,2.1a2.593,2.593,0,0,1-2.134,0L1.506,21.061a.818.818,0,0,1,0-1.474L5.16,17.938a.4.4,0,0,0,.077-.046l7.193,3.247a3.391,3.391,0,0,0,2.789,0L22.4,17.9a.4.4,0,0,0,.042.022l3.7,1.669a.818.818,0,0,1,0,1.474l-4.326,1.953a.4.4,0,0,0,.328.726l4.326-1.953a1.62,1.62,0,0,0,0-2.926ZM14.891,20.413a2.593,2.593,0,0,1-2.134,0L1.506,15.333a.818.818,0,0,1,0-1.474l4.957-2.238a6.706,6.706,0,0,0,1.691,4.516,6.241,6.241,0,0,0,4.72,2.073.4.4,0,0,0,.205-.739,5.406,5.406,0,0,1-2.329-5.32A3.843,3.843,0,0,1,12.99,9.124,5.653,5.653,0,0,1,14.5,8.652c.009.839.027,2.591.028,2.742a1.091,1.091,0,0,0,1.869.731l1.73-1.882,8.012,3.617a.818.818,0,0,1,0,1.474Z"
                                        transform="translate(26.349 17.635)"
                                        fill="#55aced"
                                    />
                                </g>
                            </svg>
                        }
                        cardTitle={t('Quick action')}
                        // className="dashb-actions"
                    >
                        <CustomButton
                            bgColor="rgba(240,90,41,.039)"
                            icon={<img src={require('../../assets/event.svg').default} alt="" />}
                            label={t('Create event')}
                            size="md"
                            onClick={() => navigate('/calendar/create')}
                        />
                        <CustomButton
                            bgColor="rgba(85,172,237,.039)"
                            icon={<img src={require('../../assets/compte.svg').default} alt="" />}
                            label={t('Create account')}
                            size="md"
                            onClick={() => navigate('/accounts/create')}
                        />
                        <CustomButton
                            bgColor="rgba(74,210,149,.039)"
                            icon={<img src={require('../../assets/contact.svg').default} alt="" />}
                            label={t('Create contact')}
                            size="md"
                            onClick={() => navigate('/contacts/create')}
                        />
                    </Card>
                    <FastActionCard
                        title={t('Points of sale pending')}
                        data={totals}
                        total={totals?.pendingPharmaciesSuggestions}
                        color="#3E79E8"
                        iconSrc={require('../../assets/drugs-icon.svg').default}
                        to="/ressources/pharmacies/history"
                    />
                    <FastActionCard
                        title={t('Healthcare facilities pending')}
                        data={totals}
                        total={totals?.pendingHealthcareEstablishmentSuggestions}
                        color="#E35757"
                        iconSrc={require('../../assets/hospital-icon.svg').default}
                        to="/ressources/centers/history"
                    />
                    <FastActionCard
                        title={t('Associations pending')}
                        data={totals}
                        total={totals?.pendingAssociationsSuggestions}
                        color="#E5B228"
                        iconSrc={require('../../assets/association-icon.svg').default}
                        to="/ressources/associations/history"
                    />
                </div>
                <div className="dashboard-posts">
                    <Posts url={`${API_URL2}/api`} user={postUserData} />
                </div>
                <Card
                    IconButton={
                        <img style={{ display: 'block' }} src={require('../../assets/event-icon.svg').default} alt="" />
                    }
                    cardTitle={t('Events of the day')}
                    className="dashb-statistics"
                >
                    <EventAccordion
                        eventsComing={eventsComing}
                        eventsVisited={eventsVisited}
                        eventsReported={eventsReported}
                    />
                </Card>
            </div>
        </section>
    );
}

export default TableauDeBord;
