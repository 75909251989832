/* eslint-disable no-bitwise */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    EmptyData,
    Table,
    TableContainer,
    TableHeader,
    TableHeaderTitle,
    TableLoader,
    TableNextPage,
    TablePage,
    TablePreviousPage,
    Th,
} from '@sobrus-com/sobrus-design-system-v2';
import { format, parseISO } from 'date-fns';

import { axiosGet } from '../../api/API';
import usePagination from '../../utils/usePagination';

function HistoricConnexion() {
    const [isFetchingUsers, setIsFetchingUsers] = useState(true);
    const [users, setUsers] = useState(null);
    const { t } = useTranslation();

    const { pageNumber, pageChange, setPageNumber } = usePagination(setIsFetchingUsers);

    useEffect(() => {
        if (isFetchingUsers) {
            const searchTerm = { orderBy: 'id', order: 'DESC', page: pageNumber, limit: 15 };
            axiosGet(`users/auth-history`, searchTerm).then((res) => {
                setUsers(res?.data?.history);
                setIsFetchingUsers(false);
            });
        }
    }, [isFetchingUsers]);

    function intToIP(int) {
        const part1 = int & 255;
        const part2 = (int >> 8) & 255;
        const part3 = (int >> 16) & 255;
        const part4 = (int >> 24) & 255;

        return `${part4}.${part3}.${part2}.${part1}`;
    }

    return (
        <TableContainer className="fade">
            <TableHeader>
                <TableHeaderTitle>{t('List of Connections')}</TableHeaderTitle>
            </TableHeader>
            <Table>
                <thead>
                    <tr>
                        <Th hideOrder>{t('Name')}</Th>
                        <Th hideOrder>{t('Prénom')}</Th>
                        <Th hideOrder>{t('Device')}</Th>
                        <Th hideOrder>{t('IP Address')}</Th>
                        <Th hideOrder>{t('Date and Time')}</Th>
                    </tr>
                </thead>
                {isFetchingUsers && (
                    <TableLoader
                        loading
                        columns={[
                            { type: 'extra-small' },
                            { type: 'extra-small' },
                            { type: 'extra-small' },
                            { type: 'extra-small' },
                            { type: 'extra-small' },
                        ]}
                    />
                )}
                <tbody>
                    {!isFetchingUsers &&
                        users &&
                        users.map((ele) => (
                            <tr>
                                <th>{ele.user?.lastName}</th>
                                <td>{ele.user?.firstName}</td>
                                <td>{ele.deviceType}</td>
                                {/* <td>{intToIP(ele.ip)}</td> */}
                                <td>{ele.ipAddress}</td>
                                <td>{ele.createdAt && format(parseISO(ele.createdAt), 'yyyy-MM-dd HH:mm')}</td>
                            </tr>
                        ))}
                    {users?.length > 0 && (
                        <tr>
                            <td colSpan={7}>
                                <div className="sob-v2-table-pagination">
                                    <TablePreviousPage onClick={() => pageChange('prev')} disabled={pageNumber <= 1} />
                                    <TablePage>{pageNumber}</TablePage>
                                    <TableNextPage onClick={() => pageChange('next')} disabled={users?.length < 15} />
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {!isFetchingUsers && users?.length < 1 && <EmptyData title={t('No results match your search')} />}
        </TableContainer>
    );
}

export default HistoricConnexion;
