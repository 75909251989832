import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
    Button,
    FormGroup,
    Input,
    Label,
    notify,
    TableContainer,
    TableHeader,
    TableHeaderTitle,
} from '@sobrus-com/sobrus-design-system-v2';

import { useAcl } from '../../../ACL/acl';
import { axiosGet, axiosPatch, axiosPost, axiosPut } from '../../../api/API';
import InputPopUp from '../../../components/inputPopUp/InputPopUp';
// import usePagination from "../../../utils/usePagination";

function Roles() {
    const [isFetchingRoles, setIsFetchingRoles] = useState(true);
    const [roles, setRoles] = useState(null);
    const [roleName, setRoleName] = useState(null);
    const [roleId, setRoleId] = useState(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const acl = useAcl();
    const { t } = useTranslation();

    // const { pageNumber, pageChange, setPageNumber } = usePagination(setIsFetchingRoles);

    useEffect(() => {
        if (isFetchingRoles) {
            axiosGet(`roles`).then((res) => {
                setRoles(res?.data?.roles);
                setIsFetchingRoles(false);
            });
            if (acl?.setting?.acl_roles?.getDefaultRoles) axiosGet(`rolepardefaut`);
        }
    }, [isFetchingRoles]);

    const editRole = () => {
        if (roleId) {
            axiosPatch(`roles/${roleId}`, { name: roleName }).then((res) => {
                if (res?.data) {
                    notify({
                        position: 'top-center',
                        type: 'success',
                        msg: 'Le rôle a été modifié avec succès',
                        delay: 5000,
                    });
                    setIsFetchingRoles(true);
                } else {
                    notify({ position: 'top-center', type: 'danger', msg: "Le rôle n'a pas été modifié", delay: 5000 });
                }
            });
        } else {
            axiosPost(`roles`, { name: roleName }).then((res) => {
                if (res?.data) {
                    notify({
                        position: 'top-center',
                        type: 'success',
                        msg: 'Le rôle a été créé avec succès',
                        delay: 5000,
                    });
                    setIsFetchingRoles(true);
                } else {
                    notify({ position: 'top-center', type: 'danger', msg: "Le rôle n'a pas été créé", delay: 5000 });
                }
            });
        }
    };

    return (
        <TableContainer className="fade">
            <div className="labs-settings-btns">
                {acl?.setting?.acl_roles?.add && (
                    <Button
                        onClick={() => {
                            setOpen(true);
                            setRoleId(null);
                        }}
                    >
                        {t('New role')}
                    </Button>
                )}
            </div>
            <TableHeader>
                <TableHeaderTitle>{t('Roles')}</TableHeaderTitle>
            </TableHeader>
            <div className="roles-cards">
                {!isFetchingRoles &&
                    roles?.length > 0 &&
                    roles?.map((ele) => (
                        <div className="roles-card" key={`${ele.id}roles`}>
                            <div>{ele.name}</div>
                            <div className="roles-btns">
                                {acl?.setting?.acl_roles?.update && (
                                    <img
                                        onClick={() => {
                                            setOpen(true);
                                            setRoleId(ele.id);
                                        }}
                                        src={require('../../../assets/edit-icon.svg').default}
                                        alt=""
                                        role="presentation"
                                    />
                                )}
                                {acl?.setting?.acl_roles?.affect_methods && (
                                    <img
                                        onClick={() => navigate(`/settings/roles/${ele.id}`)}
                                        src={require('../../../assets/view-icon.svg').default}
                                        alt=""
                                        role="presentation"
                                    />
                                )}
                            </div>
                        </div>
                    ))}
                {open && (
                    <InputPopUp
                        title={roleId ? t('Edit role') : t('Create new role')}
                        isOpen={open}
                        setIsOpen={setOpen}
                        answerCallback={editRole}
                    >
                        <FormGroup>
                            <Label>{t('Name')}</Label>
                            <Input
                                id="rolename"
                                name="rolename"
                                placeholder={t('Name')}
                                type="text"
                                onChange={(e) => setRoleName(e.target.value)}
                            />
                        </FormGroup>
                    </InputPopUp>
                )}
            </div>
        </TableContainer>
    );
}

export default Roles;
