import React from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, NavLink as RouteLink, useLocation, useNavigate } from 'react-router-dom';
import {
    MegaNavBar,
    MegaNavBarItem,
    MegaNavBarItemLink,
    MegaNavBarItems,
    MegaNavBarItemSubMenu,
    MegaNavBarItemSubMenuItem,
    MegaNavBarItemSubMenuItemLink,
    MegaNavBarLogo,
    MegaNavUser,
    MegaNavUserEmail,
    MegaNavUserInfo,
    MegaNavUserLogout,
    MegaNavUserName,
    MegaNavUserProfile,
    Nav as N,
} from '@sobrus-com/sobrus-design-system-v2';
import { Navigation } from 'values/Navigation';

// import { useNavigate } from "react-router";
import { useAcl } from '../ACL/acl';

function Nav({ user, numberNotifs }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const acl = useAcl(user);
    const { t } = useTranslation();

    // console.log("acl", acl);

    const logOut = () => {
        // fetch("https://api.fidny.sobrus.ovh/auth/revoke", { credentials: "include" }).then((res) => navigate(0));
        localStorage.removeItem('isLogin');
        window.location.reload();
    };

    // console.log();

    return (
        <MegaNavBar size="medium">
            <MegaNavBarLogo onClick={() => navigate('/')}>
                <img
                    src={require('../assets/logo.svg').default}
                    style={{
                        height: 40,
                        maxWidth: 300,
                        cursor: 'pointer',
                    }}
                    alt=""
                />
            </MegaNavBarLogo>
            <MegaNavBarItems>
                <MegaNavBarItem>
                    <RouteLink
                        to="/notifications"
                        className="notification-container"
                        style={(active) => (active.isActive ? { backgroundColor: '#fdedf1' } : null)}
                    >
                        <img
                            src={require('../assets/notif-icon.svg').default}
                            alt=""
                            style={numberNotifs > 0 ? { marginRight: '-15px' } : null}
                        />
                        {numberNotifs > 0 && <div className="notif-number">{numberNotifs}</div>}
                    </RouteLink>
                </MegaNavBarItem>
                {Navigation(acl, t)?.map(
                    (el, index) =>
                        el?.does && (
                            <MegaNavBarItem key={index}>
                                <MegaNavBarItemLink
                                    Tag={RouteLink}
                                    title={el.itemTitle}
                                    to={
                                        typeof el?.to === 'string'
                                            ? el?.to
                                            : el?.to[el?.acls?.findIndex((ele) => ele === true)]
                                    }
                                    className={
                                        el?.dropDown?.find((ele) => matchPath({ path: `${ele?.to}/*` }, pathname))
                                            ? `active`
                                            : ''
                                    }
                                >
                                    {el.itemTitle}
                                </MegaNavBarItemLink>
                                <MegaNavBarItemSubMenu>
                                    {el?.dropDown?.length !== 0 &&
                                        el?.dropDown?.map(
                                            (subEl) =>
                                                subEl?.does && (
                                                    <MegaNavBarItemSubMenuItem>
                                                        <MegaNavBarItemSubMenuItemLink
                                                            Tag={RouteLink}
                                                            title={subEl.itemTitle}
                                                            to={subEl?.to}
                                                        >
                                                            {subEl.itemTitle}
                                                        </MegaNavBarItemSubMenuItemLink>
                                                    </MegaNavBarItemSubMenuItem>
                                                )
                                        )}
                                </MegaNavBarItemSubMenu>
                            </MegaNavBarItem>
                        )
                )}
                {/* <MegaNavBarItem>
                    <MegaNavBarItemLink Tag={RouteLink} to="/" title="Accueil">
                        Accueil
                    </MegaNavBarItemLink>
                </MegaNavBarItem>
                {acl?.product.list && (
                    <MegaNavBarItem>
                        <MegaNavBarItemLink Tag={RouteLink} to="/products" title="Produits">
                            Produits
                        </MegaNavBarItemLink>
                    </MegaNavBarItem>
                )}
                {(acl?.resource.list_pharmacies ||
                    acl?.resource.list_associations ||
                    acl?.resource.list_healthcareestablishments) && (
                    <MegaNavBarItem>
                        <MegaNavBarItemLink
                            title="Ressources"
                            className={
                                (matchPath({ path: '/ressources/pharmacies/*' }, pathname) ||
                                    matchPath({ path: '/ressources/centers/*' }, pathname) ||
                                    matchPath({ path: '/ressources/associations/*' }, pathname)) &&
                                `active`
                            }
                        >
                            Ressources
                        </MegaNavBarItemLink>
                        <MegaNavBarItemSubMenu>
                            {acl?.resource.list_pharmacies && (
                                <MegaNavBarItemSubMenuItem>
                                    <MegaNavBarItemSubMenuItemLink
                                        Tag={RouteLink}
                                        to="/ressources/pharmacies"
                                        title="Points de vente"
                                    >
                                        Points de vente
                                    </MegaNavBarItemSubMenuItemLink>
                                </MegaNavBarItemSubMenuItem>
                            )}
                            {acl?.resource.list_healthcareestablishments && (
                                <MegaNavBarItemSubMenuItem>
                                    <MegaNavBarItemSubMenuItemLink
                                        Tag={RouteLink}
                                        to="/ressources/centers"
                                        title="Établissements de santé"
                                        // className="active"
                                    >
                                        Établissements de santé
                                    </MegaNavBarItemSubMenuItemLink>
                                </MegaNavBarItemSubMenuItem>
                            )}
                            {acl?.resource.list_associations && (
                                <MegaNavBarItemSubMenuItem>
                                    <MegaNavBarItemSubMenuItemLink
                                        Tag={RouteLink}
                                        to="/ressources/associations"
                                        title="Liste des Associations"
                                    >
                                        Associations
                                    </MegaNavBarItemSubMenuItemLink>
                                </MegaNavBarItemSubMenuItem>
                            )}
                        </MegaNavBarItemSubMenu>
                    </MegaNavBarItem>
                )}
                {acl?.account.list && (
                    <MegaNavBarItem>
                        <MegaNavBarItemLink Tag={RouteLink} to="/accounts" title="Comptes">
                            Comptes
                        </MegaNavBarItemLink>
                    </MegaNavBarItem>
                )}
                {acl?.contact.list && (
                    <MegaNavBarItem>
                        <MegaNavBarItemLink Tag={RouteLink} to="/contacts" title="Contacts">
                            Contacts
                        </MegaNavBarItemLink>
                    </MegaNavBarItem>
                )}
                {acl?.demand?.list && (
                    <MegaNavBarItem>
                        <MegaNavBarItemLink Tag={RouteLink} to="/requests" title="Demandes">
                            Demandes
                        </MegaNavBarItemLink>
                    </MegaNavBarItem>
                )}
                {acl?.publication?.list && (
                    <MegaNavBarItem>
                        <MegaNavBarItemLink Tag={RouteLink} to="/campaigns" title="Publications">
                            Publications
                        </MegaNavBarItemLink>
                    </MegaNavBarItem>
                )}
                <MegaNavBarItem>
                    <MegaNavBarItemLink
                        title="Ventes"
                        className={
                            (matchPath({ path: '/offers/*' }, pathname) ||
                                matchPath({ path: '/orders/*' }, pathname)) &&
                            `active`
                        }
                    >
                        Ventes
                    </MegaNavBarItemLink>
                    <MegaNavBarItemSubMenu>
                        <MegaNavBarItemSubMenuItem>
                            <MegaNavBarItemSubMenuItemLink Tag={RouteLink} to="/offers" title="Offres">
                                Offres
                            </MegaNavBarItemSubMenuItemLink>
                        </MegaNavBarItemSubMenuItem>
                        <MegaNavBarItemSubMenuItem>
                            <MegaNavBarItemSubMenuItemLink Tag={RouteLink} to="/orders" title="Bons de commande">
                                Bons de commande
                            </MegaNavBarItemSubMenuItemLink>
                        </MegaNavBarItemSubMenuItem>
                    </MegaNavBarItemSubMenu>
                </MegaNavBarItem>
                {acl?.planning?.list && (
                    <MegaNavBarItem>
                        <MegaNavBarItemLink Tag={RouteLink} to="/planning" title="Planning">
                            Planning
                        </MegaNavBarItemLink>
                    </MegaNavBarItem>
                )}
                {acl?.gallery?.list && (
                    <MegaNavBarItem>
                        <MegaNavBarItemLink Tag={RouteLink} to="/gallery" title="Galerie">
                            Galerie
                        </MegaNavBarItemLink>
                    </MegaNavBarItem>
                )}
                {acl?.calender?.list && (
                    <MegaNavBarItem>
                        <MegaNavBarItemLink Tag={RouteLink} to="/calendar" title="Calendrier">
                            Calendrier
                        </MegaNavBarItemLink>
                    </MegaNavBarItem>
                )}
                <MegaNavBarItem>
                    <MegaNavBarItemLink Tag={RouteLink} to="/reports" title="Rapports">
                        Rapports
                    </MegaNavBarItemLink>
                </MegaNavBarItem>
                <MegaNavBarItem>
                    <MegaNavBarItemLink Tag={RouteLink} to="/settings" title="Paramètres">
                        Paramètres
                    </MegaNavBarItemLink>
                </MegaNavBarItem> */}
            </MegaNavBarItems>
            {/* <MegaNavUser iconText={`${user?.nom[0]}${user?.prenom[0]}`}>
                    <MegaNavUserInfo>
                        <MegaNavUserName>{user?.fullname}</MegaNavUserName>
                        <MegaNavUserEmail>osefiani@sobrus.com</MegaNavUserEmail>
                    </MegaNavUserInfo>
                    <MegaNavUser>
                        <MegaNavUserProfile onClick={() => navigate('/settings/profil')} />
                        <MegaNavUserLogout onClick={() => logOut()} />
                    </MegaNavUser>
                </MegaNavUser> */}
            <MegaNavUser fullName={`${user?.firstName} ${user?.lastName}`}>
                <MegaNavUserProfile onClick={() => navigate('/settings/profil')} />
                <MegaNavUserLogout onClick={() => logOut()} />
            </MegaNavUser>
        </MegaNavBar>
    );
}

export default Nav;

// <div
//     style={{
//         width: "100%",
//     }}
//     className="labs-navbar fade"
//     // data-aos="fade"
// >
// <Navbar>
//             <NavbarBrand href="/">
//                 <img
//                     src={require("../assets/logo.svg").default}
//                     style={{
//                         height: 51,
//                         maxWidth: 300,
//                     }}
//                     alt=""
//                 />
//             </NavbarBrand>
//             <RouteLink to="/notifications" className="notification-container">
//                 <img src={require("../assets/notif-icon.svg").default} alt="" />
//                 <div className="notif-number">{numberNotifs || 0}</div>
//             </RouteLink>
//             <Collapse>
//                 <N>
//                     <NavItem>
//                         <RouteLink to="/" className="nav-link">
//                             Tableau de bord
//                         </RouteLink>
//                     </NavItem>
//                     {acl?.product.list && (
//                         <NavItem>
//                             <RouteLink to="/products" className="nav-link">
//                                 Produits
//                             </RouteLink>
//                         </NavItem>
//                     )}
//                     <NavItem hasSubMenu>
//                         {(acl?.resource.list_pharmacies ||
//                             acl?.resource.list_associations ||
//                             acl?.resource.list_healthcareestablishments) && (
//                             <NavLink style={{ cursor: "pointer" }} className="nav-link">
//                                 Ressources
//                             </NavLink>
//                         )}
//                         <NavSubMenu>
//                             {acl?.resource.list_pharmacies && (
//                                 <NavSubMenuItem>
//                                     <RouteLink to="/ressources/pharmacies" className="NavSubMenu__link">
//                                         Pharmacies
//                                     </RouteLink>
//                                 </NavSubMenuItem>
//                             )}
//                             {acl?.resource.list_healthcareestablishments && (
//                                 <NavSubMenuItem>
//                                     <RouteLink to="/ressources/centers" className="NavSubMenu__link">
//                                         Établissements de santé
//                                     </RouteLink>
//                                 </NavSubMenuItem>
//                             )}
//                             {acl?.resource.list_associations && (
//                                 <NavSubMenuItem>
//                                     <RouteLink to="/ressources/associations" className="NavSubMenu__link">
//                                         Liste des Associations
//                                     </RouteLink>
//                                 </NavSubMenuItem>
//                             )}
//                         </NavSubMenu>
//                     </NavItem>
//                     {acl?.account.list && (
//                         <NavItem>
//                             <RouteLink to="/accounts" className="nav-link">
//                                 Comptes
//                             </RouteLink>
//                         </NavItem>
//                     )}
//                     {acl?.contact.list && (
//                         <NavItem>
//                             <RouteLink to="/contacts" className="nav-link">
//                                 Contact
//                             </RouteLink>
//                         </NavItem>
//                     )}
//                     {acl?.demand?.list && (
//                         <NavItem>
//                             <RouteLink to="/requests" className="nav-link">
//                                 Demandes
//                             </RouteLink>
//                         </NavItem>
//                     )}
//                     {acl?.publication?.list && (
//                         <NavItem>
//                             <RouteLink to="/campaigns" className="nav-link">
//                                 Publications
//                             </RouteLink>
//                         </NavItem>
//                     )}
//                     <NavItem hasSubMenu>
//                         <NavLink style={{ cursor: "pointer" }} className="nav-link">
//                             Ventes
//                         </NavLink>
//                         <NavSubMenu>
//                             <NavSubMenuItem>
//                                 <RouteLink to="/offers" className="NavSubMenu__link">
//                                     Offres
//                                 </RouteLink>
//                             </NavSubMenuItem>
//                             <NavSubMenuItem>
//                                 <RouteLink to="/orders" className="NavSubMenu__link">
//                                     Bons de commande
//                                 </RouteLink>
//                             </NavSubMenuItem>
//                         </NavSubMenu>
//                     </NavItem>
//                     {acl?.planning?.list && (
//                         <NavItem>
//                             <RouteLink to="/planning" className="nav-link">
//                                 Planning
//                             </RouteLink>
//                         </NavItem>
//                     )}
//                     {acl?.gallery?.list && (
//                         <NavItem>
//                             <RouteLink to="/gallery" className="nav-link">
//                                 Galerie
//                             </RouteLink>
//                         </NavItem>
//                     )}
//                     {acl?.calender?.list && (
//                         <NavItem>
//                             <RouteLink to="/calendar" className="nav-link">
//                                 Calendrier
//                             </RouteLink>
//                         </NavItem>
//                     )}
//                     <NavItem>
//                         <RouteLink to="/reports" className="nav-link">
//                             Rapports
//                         </RouteLink>
//                     </NavItem>
//                     <NavItem>
//                         <RouteLink to="/settings" className="nav-link">
//                             Paramètres
//                         </RouteLink>
//                     </NavItem>
//                     <NavItemMore>
//                         <NavItemMoreBtn title="Plus" />
//                         <NavItemMoreSubMenu />
//                     </NavItemMore>
//                 </N>
//                 <NavUserProfile fullName={`${user?.fullname}`}>
//                     <DropdownItem>
//                         <RouteLink to="/settings/profil" style={{ textDecoration: "none" }}>
//                             <NavProfileLink></NavProfileLink>
//                         </RouteLink>
//                     </DropdownItem>
//                     <DropdownItem className="last-dropdown-item" style={{ cursor: "pointer" }}>
//                         <NavLogoutLink onClick={() => logOut()} />
//                     </DropdownItem>
//                 </NavUserProfile>
//             </Collapse>
//         </Navbar>
// </div>
