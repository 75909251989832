import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    Button,
    EmptyData,
    Table,
    TableContainer,
    TableHeader,
    TableHeaderTitle,
    TableLoader,
    TableNextPage,
    TablePage,
    TablePreviousPage,
    Th,
} from '@sobrus-com/sobrus-design-system-v2';

import { useAcl } from '../../../ACL/acl';
import { axiosGet, axiosPost } from '../../../api/API';
import usePagination from '../../../utils/usePagination';

function Gammes() {
    const [isFetchingGammes, setIsFetchingGammes] = useState(true);
    const [gammes, setGammes] = useState(null);
    const { pageNumber, pageChange, setPageNumber } = usePagination(setIsFetchingGammes);
    const acl = useAcl();
    const { t } = useTranslation();

    useEffect(() => {
        if (isFetchingGammes) {
            axiosGet(`products/lines`, {
                orderBy: 'id',
                order: 'DESC',
                limit: 15,
                page: pageNumber,
            }).then((res) => {
                setGammes(res?.data?.products_lines);
                setIsFetchingGammes(false);
            });
        }
    }, [isFetchingGammes]);

    return (
        <TableContainer>
            <div className="labs-settings-btns">
                {acl?.setting?.product?.range?.update && (
                    <Link to="/settings/gammes/actives">
                        <Button plus={false}>{t('Edit ranges')}</Button>
                    </Link>
                )}
            </div>
            <TableHeader>
                <TableHeaderTitle>{t('Active product ranges')}</TableHeaderTitle>
                {/* <TableHeaderActions>
                        <TableRefreshBtn onClick={refreshTable} />
                        <TableSearchBtn />
                    </TableHeaderActions> */}
            </TableHeader>
            <Table>
                <thead>
                    <tr>
                        <Th hideOrder>{t('Product range')}</Th>
                        <th aria-label="empty" width="75px" />
                        {/* <Th>Action</Th> */}
                    </tr>
                </thead>
                {isFetchingGammes && <TableLoader loading columns={[{ type: 'large' }]} />}
                <tbody>
                    {!isFetchingGammes &&
                        gammes &&
                        gammes.map((ele) => (
                            <tr key={`${ele.id}gammes`}>
                                <td>{ele.name}</td>
                                <td width="75px" />
                            </tr>
                        ))}

                    {gammes?.length > 0 && (
                        <tr>
                            <td colSpan={7}>
                                <div className="sob-v2-table-pagination">
                                    <TablePreviousPage onClick={() => pageChange('prev')} disabled={pageNumber <= 1} />
                                    <TablePage>{pageNumber}</TablePage>
                                    <TableNextPage onClick={() => pageChange('next')} disabled={gammes?.length < 15} />
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {!isFetchingGammes && gammes?.length < 1 && <EmptyData title={t('No results match your search')} />}
        </TableContainer>
    );
}

export default Gammes;
