import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import { Font } from '@react-pdf/renderer';
import { SpinnerLoading, Toast } from '@sobrus-com/sobrus-design-system-v2';
import { Review } from '@sobrus-com/sobrus-surveys-package';
// import { Review } from '@sobrus-com/sobrus-surveys-package';
import { API_URL2 } from 'env';
import poppinsBold from 'fonts/Poppins-Bold.ttf';
import poppinsBoldItalic from 'fonts/Poppins-BoldItalic.ttf';
import poppinsItalic from 'fonts/Poppins-Italic.ttf';
import poppinsLight from 'fonts/Poppins-Light.ttf';
import poppinsLightItalic from 'fonts/Poppins-LightItalic.ttf';
import poppinsMedium from 'fonts/Poppins-Medium.ttf';
import poppinsMediumItalic from 'fonts/Poppins-MediumItalic.ttf';
import poppinsRegular from 'fonts/Poppins-Regular.ttf';
import poppinsSemiBold from 'fonts/Poppins-SemiBold.ttf';
import poppinsSemiBoldItalic from 'fonts/Poppins-SemiBoldItalic.ttf';
import Failed from 'pages/Failed';
import AccountCategory from 'pages/settings/accountSettings/AccountCategory';
import APISettings from 'pages/settings/API&Integration/APISettings';
import ContactCategory from 'pages/settings/contactSettings/ContactCategory';
import MarketPlaceEmail from 'pages/settings/emailParams/MarketPlaceEmail';
import MarketPlaceHistory from 'pages/settings/emailParams/MarketPlaceHistory';
import PublicationsEmail from 'pages/settings/emailParams/PublicationsEmail';
import PublicationsHistory from 'pages/settings/emailParams/PublicationsHistory';
import CustomFields from 'pages/settings/eventsSettings/CustomFields';
import CustomReportdnd from 'pages/settings/eventsSettings/CustomReportdnd';

import { useAcl } from '../ACL/acl';
import { axiosGet } from '../api/API';
import { UserContext } from '../context/userContext';
import AccessDenied from '../pages/AccessDenied';
import TableauDeBord from '../pages/dashboard/TableauDeBord';
import Nav from '../pages/Nav';
import Notifications from '../pages/notifications/Notifications';
import NotifsHistoric from '../pages/notifications/NotifsHistoric';
import Rapports from '../pages/rapports/Rapports';
import EmailIn from '../pages/settings/EmailIn';
import EmailOut from '../pages/settings/emailOut/EmailOut';
import ModelCreation from '../pages/settings/emailOut/ModelCreation';
import Gammes from '../pages/settings/gammes/Gammes';
import GammesCheck from '../pages/settings/gammes/GammesCheck';
import HistoricConnexion from '../pages/settings/HistoricConnexion';
import Methodes from '../pages/settings/methodes/Methodes';
import Profile from '../pages/settings/Profile';
import Roles from '../pages/settings/roles/Roles';
import RolesEdit from '../pages/settings/roles/RolesEdit';
import Settings from '../pages/settings/Settings';
import EditUser from '../pages/settings/users/EditUser';
import UsersTable from '../pages/settings/users/UsersTable';
import Signin from '../pages/Signin';

import { PrivateRoute } from './RouteType';

import '../styles/labs.scss';

// products
const Page404 = lazy(() => import('pages/404/404'));
// products
const Products = lazy(() => import('../pages/products/Products'));
const ProductDetail = lazy(() => import('../pages/products/ProductDetail'));
const ProductSuggDetails = lazy(() => import('../pages/products/ProductSuggDetails'));
const SuggestProduct = lazy(() => import('../pages/products/SuggestProduct'));
const HistoricProducts = lazy(() => import('../pages/products/HistoricProducts'));
// // pharmacies
const Pharmacies = lazy(() => import('../pages/ressources/pharmacies/Pharmacies'));
const PharmaciesDetail = lazy(() => import('../pages/ressources/pharmacies/PharmaciesDetail'));
const SuggestPharmacie = lazy(() => import('../pages/ressources/pharmacies/SuggestPharmacie'));
const HistoricPharmacie = lazy(() => import('../pages/ressources/pharmacies/HistoricPharmacie'));
const PharmacieSuggDetails = lazy(() => import('../pages/ressources/pharmacies/PharmacieSuggDetails'));
// // Associations
const Associations = lazy(() => import('../pages/ressources/associations/Associations'));
const AssociationsDetail = lazy(() => import('../pages/ressources/associations/AssociationsDetail'));
const SuggestAssociation = lazy(() => import('../pages/ressources/associations/SuggestAssociation'));
const HistoricAssociations = lazy(() => import('../pages/ressources/associations/HistoricAssociations'));
const AssocSuggDetails = lazy(() => import('../pages/ressources/associations/AssocSuggDetails'));
// // centers
const Centers = lazy(() => import('../pages/ressources/centers/Centers'));
const CentersDetail = lazy(() => import('../pages/ressources/centers/CentersDetail'));
const SuggestCenter = lazy(() => import('../pages/ressources/centers/SuggestCenter'));
const HistoricCenters = lazy(() => import('../pages/ressources/centers/HistoricCenters'));
const CentersSuggDetails = lazy(() => import('../pages/ressources/centers/CentersSuggDetails'));
// // Comptes
const Comptes = lazy(() => import('../pages/comptes/Comptes'));
const ComptesDetail = lazy(() => import('../pages/comptes/ComptesDetail'));
const CompteCreation = lazy(() => import('../pages/comptes/CompteCreation'));
const ComptesArchive = lazy(() => import('../pages/comptes/ComptesArchive'));
// // Contact
const Contacts = lazy(() => import('../pages/contacts/Contacts'));
const ContactsDetail = lazy(() => import('../pages/contacts/ContactsDetail'));
const ContactCreation = lazy(() => import('../pages/contacts/ContactCreation'));
const ContactsArchive = lazy(() => import('../pages/contacts/ContactsArchive'));
// // Plannings
const PlanningDetail = lazy(() => import('../pages/planning/PlanningDetail'));
const PlanningCreation = lazy(() => import('../pages/planning/PlanningCreation'));
const PlanningArchive = lazy(() => import('../pages/planning/PlanningArchive'));
const PlanningEventsAdd = lazy(() => import('../pages/planning/PlanningEventsAdd'));
const PlanningEventCreation = lazy(() => import('../pages/planning/PlanningEventCreation'));
const Planning = lazy(() => import('../pages/planning/Planning'));
const PlanningDuplicate = lazy(() => import('../pages/planning/PlanningDuplicate'));
// // Demandes
const Demandes = lazy(() => import('../pages/demandes/Demandes'));
const DemandeDetails = lazy(() => import('../pages/demandes/DemandeDetails'));
const DemandeCreation = lazy(() => import('../pages/demandes/DemandeCreation'));
// // Galerie
const Galerie = lazy(() => import('../pages/galerie/Galerie'));
const GalerieDetail = lazy(() => import('../pages/galerie/GalerieDetail'));
const GalerieArchive = lazy(() => import('../pages/galerie/GalerieArchive'));
const GalerieCreation = lazy(() => import('../pages/galerie/GalerieCreation'));
// Calender
const Calender = lazy(() => import('../pages/calender/Calender'));
const CalenderDetail = lazy(() => import('../pages/calender/CalenderDetail'));
const CalenderCreation = lazy(() => import('../pages/calender/CalenderCreation'));
const PostponeEvent = lazy(() => import('../pages/calender/PostponeEvent'));
const RapportCreation = lazy(() => import('../pages/calender/RapportCreation'));
// Loyalty programs
const Programs = lazy(() => import('pages/loyalty/loyaltyPrograms/Programs'));
const ProgramsDetails = lazy(() => import('pages/loyalty/loyaltyPrograms/ProgramsDetails'));
const ProgramsCreation = lazy(() => import('pages/loyalty/loyaltyPrograms/ProgramsCreation'));
// Loyalty memberships
const Memberships = lazy(() => import('pages/loyalty/loyaltyMemberships/Memberships'));
const MembershipsDetails = lazy(() => import('pages/loyalty/loyaltyMemberships/MembershipsDetails'));
const MembershipsCreation = lazy(() => import('pages/loyalty/loyaltyMemberships/MembershipsCreation'));
// // Publications
const Publications = lazy(() => import('../pages/publications/Publications'));
const PubCreation = lazy(() => import('../pages/publications/PubCreation'));
const PublicationDetails = lazy(() => import('../pages/publications/PublicationDetails'));
// // Offers
const Offers = lazy(() => import('../pages/ventes/Offers/Offers'));
const OfferDetails = lazy(() => import('../pages/ventes/Offers/OfferDetails'));
const OfferCreation = lazy(() => import('../pages/ventes/Offers/OfferCreation'));
const OffersView = lazy(() => import('../pages/ventes/Offers/OffersView'));
// // Orders
const Orders = lazy(() => import('../pages/ventes/orders/Orders'));
const OrderDetails = lazy(() => import('../pages/ventes/orders/OrderDetails'));
const OrderCreation = lazy(() => import('../pages/ventes/orders/OrderCreation'));
// Stores
const CustomStores = lazy(() => import('pages/ventes/stores/CustomStores'));
const CustomStoresCreation = lazy(() => import('pages/ventes/stores/CustomStoresCreation'));
// // Rapports
const REventPerCanal = lazy(() => import('../pages/rapports/RapportEvent/REventPerCanal'));
const REventPerObj = lazy(() => import('../pages/rapports/RapportEvent/REventPerObj'));
const REventPerCat = lazy(() => import('../pages/rapports/RapportEvent/REventPerCat'));
const REventGeneral = lazy(() => import('../pages/rapports/RapportEvent/REventGeneral'));
const RContactPerUser = lazy(() => import('../pages/rapports/rapportContact/RContactPerUser'));
// import NewMembre from "../pages/groups/NewMembre";

Font.register({
    family: 'Poppins',
    fonts: [
        { src: poppinsRegular }, // font-style: normal, font-weight: normal
        { src: poppinsItalic, fontStyle: 'italic' },
        { src: poppinsLight, fontStyle: 'normal', fontWeight: 300 },
        { src: poppinsLightItalic, fontStyle: 'italic', fontWeight: 300 },
        { src: poppinsMedium, fontStyle: 'normal', fontWeight: 500 },
        { src: poppinsMediumItalic, fontStyle: 'italic', fontWeight: 500 },
        { src: poppinsSemiBold, fontStyle: 'normal', fontWeight: 600 },
        { src: poppinsSemiBoldItalic, fontStyle: 'italic', fontWeight: 600 },
        { src: poppinsBold, fontStyle: 'normal', fontWeight: 700 },
        { src: poppinsBoldItalic, fontStyle: 'italic', fontWeight: 700 },
    ],
});

function HomeNavigator() {
    const {
        isLogin,
        setIsLogin,
        user,
        setUser,
        setNotifs,
        isFetchingNotifs,
        setIsFetchingNotifs,
        numberNotifs,
        setNumbetNotifs,
        isFetching,
        setIsFetching,
    } = useContext(UserContext);
    const [aclNotif, setAclNotif] = useState(null);
    const location = useLocation();
    const acl = useAcl(user);

    // function getCookie(name) {
    //     var match = document.cookie.match(RegExp("(?:^|;\\s*)" + name + "=([^;]*)"));
    //     return match ? match[1] : null;
    // }

    useEffect(() => {
        if (localStorage.getItem('isLogin')) {
            setIsLogin(localStorage.getItem('isLogin'));
            if (isLogin && isFetching) {
                axiosGet('users/authenticated-user').then((res) => {
                    if (res?.data) {
                        setUser(res?.data);
                        // acl.setCurrUser(res?.data);
                        setAclNotif(acl?.notification?.list);
                        setIsFetching(false);
                    }
                });
            }
        } else if (location.pathname === '/auth/success' && !isLogin) {
            axiosGet('users/authenticated-user').then((res) => {
                setIsLogin(true);
                localStorage.setItem('isLogin', true);
                setUser(res?.data);
                // acl.setCurrUser(res?.data);
                setAclNotif(acl?.notification?.list);
                setIsFetching(false);
            });
        } else {
            setIsFetching(false);
        }
    }, [isLogin]);

    useEffect(() => {
        setAclNotif(acl?.notification?.list);
    }, [acl]);

    useEffect(() => {
        if (isFetchingNotifs && isLogin && aclNotif) {
            axiosGet('notifications/get').then((res) => {
                if (res) {
                    setNotifs(res?.data?.notifications);
                    setNumbetNotifs(res?.data?.notifications?.length);
                    setIsFetchingNotifs(false);
                }
            });
        }
        const interval = setInterval(() => {
            if (isLogin && aclNotif) {
                axiosGet('notifications/get').then((res) => {
                    if (res) {
                        setNotifs(res?.data?.notifications);
                        setNumbetNotifs(res?.data?.notifications?.length);
                    }
                });
            }
        }, 15000);
        return () => clearInterval(interval);
    }, [isFetchingNotifs, isLogin, aclNotif]);

    if (!isFetching)
        return (
            <div className="labs">
                <Toast />
                {isLogin && <Nav user={user} numberNotifs={numberNotifs} />}
                {isLogin && <Review baseUrl={API_URL2} />}
                {/* <TableauDeBord /> */}
                <Routes>
                    <Route path="/" element={<PrivateRoute component={<TableauDeBord />} acl=" " />} />
                    {/* Notifications */}
                    <Route
                        path="/notifications"
                        element={<PrivateRoute component={<Notifications />} acl={acl?.notification?.list} />}
                    />
                    <Route
                        path="/notifications/history"
                        element={<PrivateRoute component={<NotifsHistoric />} acl={acl?.notification?.list_history} />}
                    />
                    {/* Products */}
                    <Route
                        path="/products"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <Products />
                                    </Suspense>
                                }
                                acl={acl?.product.list}
                            />
                        }
                    />
                    <Route
                        path="/products/history"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <HistoricProducts />
                                    </Suspense>
                                }
                                acl={acl?.product?.suggestion?.list}
                            />
                        }
                    />
                    <Route
                        path="/products/suggestion/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <ProductSuggDetails />
                                    </Suspense>
                                }
                                acl={acl?.product?.suggestion?.details}
                            />
                        }
                    />
                    <Route
                        path="/products/details/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <ProductDetail />
                                    </Suspense>
                                }
                                acl={acl?.product?.details}
                            />
                        }
                    />
                    <Route
                        path="/products/suggest"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <SuggestProduct />
                                    </Suspense>
                                }
                                acl={acl?.product?.suggestion?.add}
                            />
                        }
                    />
                    <Route
                        path="/products/suggest/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <SuggestProduct />
                                    </Suspense>
                                }
                                acl={acl?.product?.suggestion?.add}
                            />
                        }
                    />
                    {/* Pharmacie */}
                    <Route
                        path="/ressources/pharmacies"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <Pharmacies />
                                    </Suspense>
                                }
                                acl={acl?.resource?.list_pharmacies}
                            />
                        }
                    />
                    <Route
                        path="/ressources/pharmacies/history"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <HistoricPharmacie />
                                    </Suspense>
                                }
                                acl={acl?.resource?.suggestion?.pharmacy?.list}
                            />
                        }
                    />
                    <Route
                        path="/ressources/pharmacies/details/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <PharmaciesDetail />
                                    </Suspense>
                                }
                                acl={acl?.resource?.pharmacy_details}
                            />
                        }
                    />
                    <Route
                        path="/ressources/pharmacies/suggestion/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <PharmacieSuggDetails />
                                    </Suspense>
                                }
                                acl={acl?.resource?.suggestion?.pharmacy?.details}
                            />
                        }
                    />
                    <Route
                        path="/ressources/pharmacies/suggest"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <SuggestPharmacie />
                                    </Suspense>
                                }
                                acl={acl?.resource?.suggestion?.pharmacy?.add}
                            />
                        }
                    />
                    <Route
                        path="/ressources/pharmacies/suggest/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <SuggestPharmacie />
                                    </Suspense>
                                }
                                acl={acl?.resource?.suggestion?.pharmacy?.add}
                            />
                        }
                    />
                    {/* Centres */}
                    <Route
                        path="/ressources/centers"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <Centers />
                                    </Suspense>
                                }
                                acl={acl?.resource?.list_associations}
                            />
                        }
                    />
                    <Route
                        path="/ressources/centers/history"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <HistoricCenters />
                                    </Suspense>
                                }
                                acl={acl?.resource?.suggestion?.healthcareestablishments?.list}
                            />
                        }
                    />

                    <Route
                        path="/ressources/centers/suggest"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <SuggestCenter />
                                    </Suspense>
                                }
                                acl={acl?.resource?.suggestion?.healthcareestablishments?.add}
                            />
                        }
                    />
                    <Route
                        path="/ressources/centers/suggest/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <SuggestCenter />
                                    </Suspense>
                                }
                                acl={acl?.resource?.suggestion?.healthcareestablishments?.add}
                            />
                        }
                    />
                    <Route
                        path="/ressources/centers/details/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <CentersDetail />
                                    </Suspense>
                                }
                                acl={acl?.resource?.healthcareestablishments_details}
                            />
                        }
                    />
                    <Route
                        path="/ressources/centers/suggestion/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <CentersSuggDetails />
                                    </Suspense>
                                }
                                acl={acl?.resource?.suggestion?.healthcareestablishments?.details}
                            />
                        }
                    />
                    {/* Associations */}
                    <Route
                        path="/ressources/associations"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <Associations />
                                    </Suspense>
                                }
                                acl={acl?.resource?.list_associations}
                            />
                        }
                    />
                    <Route
                        path="/ressources/associations/history"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <HistoricAssociations />
                                    </Suspense>
                                }
                                acl={acl?.resource?.suggestion?.associations?.list}
                            />
                        }
                    />
                    <Route
                        path="/ressources/associations/suggest"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <SuggestAssociation />
                                    </Suspense>
                                }
                                acl={acl?.resource?.suggestion?.associations?.add}
                            />
                        }
                    />
                    <Route
                        path="/ressources/associations/suggest/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <SuggestAssociation />
                                    </Suspense>
                                }
                                acl={acl?.resource?.suggestion?.associations?.add}
                            />
                        }
                    />
                    <Route
                        path="/ressources/associations/details/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <AssociationsDetail />
                                    </Suspense>
                                }
                                acl={acl?.resource?.associations_details}
                            />
                        }
                    />
                    <Route
                        path="/ressources/associations/suggestion/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <AssocSuggDetails />
                                    </Suspense>
                                }
                                acl={acl?.resource?.suggestion?.associations?.details}
                            />
                        }
                    />
                    {/* Comptes */}
                    <Route
                        path="/accounts"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <Comptes />
                                    </Suspense>
                                }
                                acl={acl?.account?.list}
                            />
                        }
                    />
                    <Route
                        path="/accounts/archive"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <ComptesArchive />
                                    </Suspense>
                                }
                                acl={acl?.account?.list}
                            />
                        }
                    />
                    <Route
                        path="/accounts/create"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <CompteCreation />
                                    </Suspense>
                                }
                                acl={acl?.account?.add}
                            />
                        }
                    />
                    <Route
                        path="/accounts/create/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <CompteCreation />
                                    </Suspense>
                                }
                                acl={acl?.account?.update}
                            />
                        }
                    />
                    <Route
                        path="/accounts/details/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <ComptesDetail />
                                    </Suspense>
                                }
                                acl={acl?.account?.details}
                            />
                        }
                    />
                    {/* Contacts */}
                    <Route
                        path="/contacts"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <Contacts />
                                    </Suspense>
                                }
                                acl={acl?.contact?.list}
                            />
                        }
                    />
                    <Route
                        path="/contacts/archive"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <ContactsArchive />
                                    </Suspense>
                                }
                                acl={acl?.contact?.list}
                            />
                        }
                    />
                    <Route
                        path="/contacts/create"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <ContactCreation />
                                    </Suspense>
                                }
                                acl={acl?.contact?.add}
                            />
                        }
                    />
                    <Route
                        path="/contacts/create/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <ContactCreation />
                                    </Suspense>
                                }
                                acl={acl?.contact?.update}
                            />
                        }
                    />
                    <Route
                        path="/contacts/details/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <ContactsDetail />
                                    </Suspense>
                                }
                                acl={acl?.contact?.details}
                            />
                        }
                    />
                    {/* Loyalty -> Programs */}
                    <Route
                        path="/loyalty/programs"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <Programs />
                                    </Suspense>
                                }
                                acl={acl?.publication?.list}
                            />
                        }
                    />
                    <Route
                        path="/loyalty/programs/create"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <ProgramsCreation />
                                    </Suspense>
                                }
                                acl={acl?.publication?.add}
                            />
                        }
                    />
                    <Route
                        path="/loyalty/programs/create/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <ProgramsCreation />
                                    </Suspense>
                                }
                                acl={acl?.publication?.update}
                            />
                        }
                    />
                    <Route
                        path="/loyalty/programs/details/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <ProgramsDetails />
                                    </Suspense>
                                }
                                acl={acl?.publication?.single}
                            />
                        }
                    />
                    {/* Loyalty -> Memberships */}
                    <Route
                        path="/loyalty/memberships"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <Memberships />
                                    </Suspense>
                                }
                                acl={acl?.publication?.list}
                            />
                        }
                    />
                    <Route
                        path="/loyalty/memberships/create"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <MembershipsCreation />
                                    </Suspense>
                                }
                                acl={acl?.publication?.add}
                            />
                        }
                    />
                    <Route
                        path="/loyalty/memberships/create/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <MembershipsCreation />
                                    </Suspense>
                                }
                                acl={acl?.publication?.update}
                            />
                        }
                    />
                    <Route
                        path="/loyalty/memberships/details/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <MembershipsDetails />
                                    </Suspense>
                                }
                                acl={acl?.publication?.single}
                            />
                        }
                    />
                    {/* Publications */}
                    <Route
                        path="/campaigns"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <Publications />
                                    </Suspense>
                                }
                                acl={acl?.publication?.list}
                            />
                        }
                    />
                    <Route
                        path="/campaigns/create"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <PubCreation />
                                    </Suspense>
                                }
                                acl={acl?.publication?.add}
                            />
                        }
                    />
                    <Route
                        path="/campaigns/create/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <PubCreation />
                                    </Suspense>
                                }
                                acl={acl?.publication?.update}
                            />
                        }
                    />
                    <Route
                        path="/campaigns/details/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <PublicationDetails />
                                    </Suspense>
                                }
                                acl={acl?.publication?.single}
                            />
                        }
                    />
                    {/* Planning */}
                    <Route
                        path="/planning"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <Planning />
                                    </Suspense>
                                }
                                acl={acl?.planning?.list}
                            />
                        }
                    />
                    <Route
                        path="/planning/details/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <PlanningDetail />
                                    </Suspense>
                                }
                                acl={acl?.planning?.details}
                            />
                        }
                    />
                    <Route
                        path="/planning/archive"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <PlanningArchive />
                                    </Suspense>
                                }
                                acl={acl?.planning?.list}
                            />
                        }
                    />
                    <Route
                        path="/planning/create"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <PlanningCreation />
                                    </Suspense>
                                }
                                acl={acl?.planning?.add}
                            />
                        }
                    />
                    <Route
                        path="/planning/create/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <PlanningCreation />
                                    </Suspense>
                                }
                                acl={acl?.planning?.update}
                            />
                        }
                    />
                    <Route
                        path="/planning/duplicate/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <PlanningDuplicate />
                                    </Suspense>
                                }
                                acl={acl?.planning?.add}
                            />
                        }
                    />
                    <Route
                        path="/planning/event/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <PlanningEventsAdd />
                                    </Suspense>
                                }
                                acl={acl?.planning?.event_to_planning}
                            />
                        }
                    />
                    <Route
                        path="/planning/new-event/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <PlanningEventCreation />
                                    </Suspense>
                                }
                                acl={acl?.planning?.new_event_to_planning}
                            />
                        }
                    />
                    {/* Galerie */}
                    <Route
                        path="/gallery"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <Galerie />
                                    </Suspense>
                                }
                                acl={acl?.gallery?.list}
                            />
                        }
                    />
                    <Route
                        path="/gallery/details/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <GalerieDetail />
                                    </Suspense>
                                }
                                acl={acl?.gallery?.details}
                            />
                        }
                    />
                    <Route
                        path="/gallery/archive"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <GalerieArchive />
                                    </Suspense>
                                }
                                acl={acl?.gallery?.list}
                            />
                        }
                    />
                    <Route
                        path="/gallery/create"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <GalerieCreation />
                                    </Suspense>
                                }
                                acl={acl?.gallery?.add}
                            />
                        }
                    />
                    <Route
                        path="/gallery/create/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <GalerieCreation />
                                    </Suspense>
                                }
                                acl={acl?.gallery?.update}
                            />
                        }
                    />
                    {/* Calender */}
                    <Route
                        path="/calendar"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <Calender />
                                    </Suspense>
                                }
                                acl={acl?.calender?.list}
                            />
                        }
                    />
                    <Route
                        path="/calendar/details/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <CalenderDetail />
                                    </Suspense>
                                }
                                acl={acl?.calender?.details}
                            />
                        }
                    />
                    {/* <Route path="/calendar/archive" element={<PrivateRoute component={<GalerieArchive />} />} /> */}
                    <Route
                        path="/calendar/create"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <CalenderCreation />
                                    </Suspense>
                                }
                                acl={acl?.calender?.add}
                            />
                        }
                    />
                    <Route
                        path="/calendar/create/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <CalenderCreation />
                                    </Suspense>
                                }
                                acl={acl?.calender?.update}
                            />
                        }
                    />
                    <Route
                        path="/calendar/report"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <RapportCreation />
                                    </Suspense>
                                }
                                acl={acl?.crEvent?.add}
                            />
                        }
                    />
                    <Route
                        path="/calendar/report/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <RapportCreation />
                                    </Suspense>
                                }
                                acl={acl?.crEvent?.update}
                            />
                        }
                    />
                    <Route
                        path="/calendar/postpone-event/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <PostponeEvent />
                                    </Suspense>
                                }
                                acl={acl?.calender?.report}
                            />
                        }
                    />
                    {/* Demandes */}
                    <Route
                        path="/requests"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <Demandes />
                                    </Suspense>
                                }
                                acl={acl?.demand?.list}
                            />
                        }
                    />
                    <Route
                        path="/requests/details/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <DemandeDetails />
                                    </Suspense>
                                }
                                acl={acl?.demand?.details}
                            />
                        }
                    />
                    <Route
                        path="/requests/create"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <DemandeCreation />
                                    </Suspense>
                                }
                                acl={acl?.demand?.add}
                            />
                        }
                    />
                    <Route
                        path="/requests/create/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <DemandeCreation />
                                    </Suspense>
                                }
                                acl={acl?.demand?.update}
                            />
                        }
                    />
                    {/* Ventes */}
                    {/* Ventes -> Offers */}
                    <Route
                        path="/offers"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <Offers />
                                    </Suspense>
                                }
                                acl={acl?.sales?.offers?.list}
                            />
                        }
                    />
                    <Route
                        path="/offers/marketplace"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <OffersView />
                                    </Suspense>
                                }
                                acl={acl?.sales?.offers?.list}
                            />
                        }
                    />
                    <Route
                        path="/offers/details/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <OfferDetails />
                                    </Suspense>
                                }
                                acl={acl?.sales?.offers?.details}
                            />
                        }
                    />
                    <Route
                        path="/offers/create"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <OfferCreation />
                                    </Suspense>
                                }
                                acl={acl?.sales?.offers?.add}
                            />
                        }
                    />
                    <Route
                        path="/offers/create/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <OfferCreation />
                                    </Suspense>
                                }
                                acl={acl?.sales?.offers?.update}
                            />
                        }
                    />
                    {/* Ventes -> Orders */}
                    <Route
                        path="/orders"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <Orders />
                                    </Suspense>
                                }
                                acl={acl?.sales?.orders?.list}
                            />
                        }
                    />
                    <Route
                        path="/orders/details/:id"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <OrderDetails />
                                    </Suspense>
                                }
                                acl={acl?.sales?.orders?.details}
                            />
                        }
                    />
                    <Route
                        path="/orders/create"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <OrderCreation />
                                    </Suspense>
                                }
                                acl={acl?.sales?.orders?.add}
                            />
                        }
                    />
                    {/* Ventes -> Orders */}
                    <Route
                        path="/custom-stores"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <CustomStores />
                                    </Suspense>
                                }
                                acl={acl?.sales?.stores?.list}
                            />
                        }
                    />
                    <Route
                        path="/custom-stores/create"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <CustomStoresCreation />
                                    </Suspense>
                                }
                                acl={acl?.sales?.stores?.add}
                            />
                        }
                    />
                    {/* Rapports */}
                    <Route path="/reports" element={<PrivateRoute component={<Rapports />} acl={' '} />} />
                    <Route
                        path="/reports/event-general"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <REventGeneral />
                                    </Suspense>
                                }
                                acl={acl?.report?.visits}
                            />
                        }
                    />
                    <Route
                        path="/reports/event-per-canal"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <REventPerCanal />
                                    </Suspense>
                                }
                                acl={acl?.report?.visitor_by_channel}
                            />
                        }
                    />
                    <Route
                        path="/reports/event-per-objectif"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <REventPerObj />
                                    </Suspense>
                                }
                                acl={acl?.report?.visitor_by_purpose}
                            />
                        }
                    />
                    <Route
                        path="/reports/event-per-categorie"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <REventPerCat />
                                    </Suspense>
                                }
                                acl={acl?.report?.visitor_by_contact_category}
                            />
                        }
                    />
                    <Route
                        path="/reports/contact-per-user"
                        element={
                            <PrivateRoute
                                component={
                                    <Suspense fallback={<Loader />}>
                                        <RContactPerUser />
                                    </Suspense>
                                }
                                acl={acl?.report?.contact_by_user}
                            />
                        }
                    />
                    {/* Settings */}
                    <Route path="/settings" element={<PrivateRoute component={<Settings />} acl={' '} />}>
                        <Route index element={<Profile />} />
                        <Route path="/settings/profil" element={<Profile />} />
                        {acl?.setting?.user?.list && <Route path="/settings/users" element={<UsersTable />} />}
                        {acl?.setting?.user?.update && (
                            <Route path="/settings/users/modification/:id" element={<EditUser />} />
                        )}
                        {acl?.setting?.user?.connection_history && (
                            <Route path="/settings/connections-history" element={<HistoricConnexion />} />
                        )}
                        {acl?.demand?.listEmailAddress && <Route path="/settings/emails-in" element={<EmailIn />} />}
                        {acl?.demand?.listModalEmailAddress && (
                            <Route path="/settings/emails-out" element={<EmailOut />} />
                        )}
                        {acl?.demand?.addModel && (
                            <Route path="/settings/emails-out/modele-creation" element={<ModelCreation />} />
                        )}
                        {acl?.demand?.updateModel && (
                            <Route path="/settings/emails-out/modele-creation/:id" element={<ModelCreation />} />
                        )}
                        {acl?.setting?.product?.range?.list && <Route path="/settings/gammes" element={<Gammes />} />}
                        {acl?.setting?.product?.range?.update && (
                            <Route path="/settings/gammes/actives" element={<GammesCheck />} />
                        )}
                        {acl?.setting?.acl_roles?.list && <Route path="/settings/roles" element={<Roles />} />}
                        {acl?.setting?.acl_roles?.affect_methods && (
                            <Route path="/settings/roles/:id" element={<RolesEdit />} />
                        )}
                        {acl?.setting?.acl_methods?.list && <Route path="/settings/methodes" element={<Methodes />} />}
                        <Route path="/settings/accountCategory" element={<AccountCategory />} />
                        <Route path="/settings/contactCategory" element={<ContactCategory />} />
                        <Route path="/settings/rapportPublication" element={<PublicationsEmail />} />
                        <Route path="/settings/rapportPublication/history" element={<PublicationsHistory />} />
                        <Route path="/settings/rapportMarketplace" element={<MarketPlaceEmail />} />
                        <Route path="/settings/rapportMarketplace/history" element={<MarketPlaceHistory />} />
                        <Route path="/settings/customFields" element={<CustomFields />} />
                        <Route path="/settings/customReport" element={<CustomReportdnd />} />
                        <Route path="/settings/api" element={<APISettings />} />
                    </Route>
                    {/* Sign In */}
                    <Route path="/signin" element={<Signin />} />
                    <Route path="/access-denied" element={<AccessDenied />} />
                    <Route path="/failed" element={<Failed />} />
                    <Route path="/auth/success" element={<Navigate to="/" />} />
                    <Route
                        path="*"
                        element={
                            <Suspense fallback={<Loader />}>
                                <Page404 />
                            </Suspense>
                        }
                    />
                </Routes>
            </div>
        );
    return <Loader />;
}

function Loader() {
    return (
        <div className="labs">
            <div className="loader">
                <SpinnerLoading color="#DF4751" loading />
            </div>
        </div>
    );
}

export default HomeNavigator;
