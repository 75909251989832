import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEye } from 'react-icons/fi';
import { Col } from '@sobrus-com/sobrus-design-system-v2';

import {
    dragDropMove,
    dragDropReorder,
    Draggable,
    DraggableItem,
    Droppable,
    DroppableContainer,
    DroppableTitle,
    SobDragDropContext,
} from './DragDropComponents';

const id2List = {
    droppable: 'menuItems',
    droppable2: 'selected',
};

function DragDrop({ setSelectedItems, firstTable, secondTable }) {
    // const [menuItems, setMenuItems] = useState(firstTable);
    // const [selected, setSelected] = useState(secondTable);
    const { t } = useTranslation();

    const getList = (id) => (id2List[id] === 'menuItems' ? firstTable : secondTable);

    const onDragEnd = (result) => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const Items = dragDropReorder(getList(source.droppableId), source.index, destination.index);

            let state = { menuItems: Items, selected: secondTable };

            if (source.droppableId === 'droppable2') {
                state = { selected: Items };
            }
            console.log(Items);
            setSelectedItems((prev) => ({
                ...prev,
                ...state,
                dataIsChanged: true,
            }));
            // if (state?.menuItems) setMenuItems(state.menuItems);
            // if (state?.selected) setSelected(state.selected);
        } else {
            const res = dragDropMove(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination
            );
            // setMenuItems(res.droppable);
            // setSelected(res.droppable2);
            console.log('res', res);
            setSelectedItems({
                selected: res.droppable2,
                menuItems: res.droppable,
                dataIsChanged: true,
            });
        }
    };

    return (
        <div>
            <SobDragDropContext onDragEnd={onDragEnd}>
                <Col>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <DroppableContainer
                                ref={provided.innerRef}
                                isDraggingOver={snapshot.isDraggingOver}
                                title={<DroppableTitle>{t('Invisible')}</DroppableTitle>}
                            >
                                <>
                                    {firstTable?.map((item, index) => (
                                        <Draggable key={index + 1} draggableId={`list A ${index + 1}`} index={index}>
                                            {(prov, snap) => (
                                                <DraggableItem
                                                    ref={prov.innerRef}
                                                    isDragging={snap.isDragging}
                                                    draggableStyle={prov.draggableProps.style}
                                                    {...prov.draggableProps}
                                                    {...prov.dragHandleProps}
                                                >
                                                    {item.fieldName}
                                                </DraggableItem>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </>
                            </DroppableContainer>
                        )}
                    </Droppable>
                </Col>
                <Col>
                    <Droppable droppableId="droppable2">
                        {(provided, snapshot) => (
                            <DroppableContainer
                                ref={provided.innerRef}
                                isDraggingOver={snapshot.isDraggingOver}
                                title={
                                    <DroppableTitle
                                        bgColor="rgba(27, 186, 172, 0.08)"
                                        textColor="#1bbaac"
                                        icon={<FiEye color="#1bbaac" size={17} />}
                                    >
                                        {t('Visible')}
                                    </DroppableTitle>
                                }
                            >
                                <>
                                    {secondTable?.map((item, index) => (
                                        <Draggable key={index + 1} draggableId={`list B ${index + 1}`} index={index}>
                                            {(prov, snap) => (
                                                <DraggableItem
                                                    ref={prov.innerRef}
                                                    isDragging={snap.isDragging}
                                                    draggableStyle={prov.draggableProps.style}
                                                    {...prov.draggableProps}
                                                    {...prov.dragHandleProps}
                                                    disabled={item?.lock}
                                                >
                                                    {item.fieldName}
                                                </DraggableItem>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </>
                            </DroppableContainer>
                        )}
                    </Droppable>
                </Col>
            </SobDragDropContext>
        </div>
    );
}

export default DragDrop;
