import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    CheckBox,
    EmptyData,
    Input,
    notify,
    SearchBtn,
    Table,
    TableContainer,
    TableHeader,
    TableHeaderActions,
    TableHeaderTitle,
    TableLoader,
    TableNextPage,
    TablePage,
    TablePreviousPage,
    TableRefreshBtn,
    TableSearch,
    TableSearchBtn,
    TableSearchItem,
    TableSearchRow,
    Th,
} from '@sobrus-com/sobrus-design-system-v2';

import { axiosGet, axiosPost } from '../../../api/API';
import BackwardTitle from '../../../components/backwardTitle/BackwardTitle';
import { useLocalStorage } from '../../../utils/useLocalStorage';
import usePagination from '../../../utils/usePagination';

function GammesCheck() {
    const [isFetchingGammes, setIsFetchingGammes] = useState(true);
    const [gammes, setGammes] = useState(null);
    const [disableBtn, setDisableBtn] = useState(false);
    const [activeGammes, setActiveGammes] = useState([]);
    const [toggle, setToggle] = useState(false);
    const [nompInput, setNompInput] = useLocalStorage('nomGammes', '');
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { pageNumber, pageChange, setStoredPage } = usePagination(setIsFetchingGammes);

    const handleSearch = () => {
        const searchTerm = {};

        if (nompInput !== '') {
            searchTerm.name = nompInput;
        }

        setIsFetchingGammes(true);

        return searchTerm;
    };

    const refreshTable = () => {
        setNompInput('');
        setStoredPage(1);
        setIsFetchingGammes(true);
    };

    useEffect(() => {
        if (isFetchingGammes) {
            const searchTerm = { ...handleSearch(), orderBy: 'id', order: 'DESC', page: pageNumber, limit: 20 };
            axiosGet(`products/lines`, searchTerm).then((res) => {
                setGammes(res?.data?.products_lines);
                setIsFetchingGammes(false);
            });
        }
    }, [isFetchingGammes]);

    useEffect(() => {
        axiosGet(`products/lines`, {
            onlyActive: true,
            orderBy: 'id',
            order: 'DESC',
        }).then((res) => {
            if (res?.data?.products_lines?.length > 0) {
                setActiveGammes(res?.data?.products_lines);
            }
            // console.log(arrActives.current);
        });
    }, []);

    const addGammes = () => {
        if (!disableBtn) {
            setDisableBtn(true);
            axiosPost('products/lines/active', { productLinesIds: activeGammes?.map((ele) => ele?.id) }).then((res) => {
                if (res?.data) {
                    notify({
                        position: 'top-center',
                        type: 'success',
                        msg: 'Les gammes ont été ajoutées avec succès',
                        delay: 5000,
                    });
                    navigate('/settings/gammes');
                } else {
                    notify({
                        position: 'top-center',
                        type: 'danger',
                        msg: "Les gammes n'ont pas été ajoutée",
                        delay: 5000,
                    });
                    setDisableBtn(false);
                }
            });
        }
    };

    return (
        <TableContainer>
            <div className="labs-settings-btns">
                <Button onClick={addGammes}>{t('Save')}</Button>
            </div>
            <BackwardTitle title={t('Edit active product ranges')} to="/settings/gammes" />
            <TableHeader>
                <TableHeaderTitle>{t('Product range')}</TableHeaderTitle>
                <TableHeaderActions>
                    <TableRefreshBtn onClick={refreshTable} />
                    <TableSearchBtn onClick={() => setToggle(!toggle)} open={toggle} />
                </TableHeaderActions>
            </TableHeader>
            <Table>
                <thead>
                    <tr>
                        <th aria-label="empty cell" />
                        <Th>{t('Name')}</Th>
                        <th aria-label="empty" width="75px" />
                        {/* <Th>Action</Th> */}
                    </tr>
                    <TableSearchRow id="simple" open={toggle}>
                        <TableSearchItem />
                        <TableSearchItem>
                            <Input
                                id="nomp"
                                name="nomp"
                                placeholder={t('Name')}
                                type="text"
                                onChange={(e) => setNompInput(e.target.value)}
                                value={nompInput}
                            />
                        </TableSearchItem>
                        <TableSearchItem>
                            <SearchBtn
                                onClick={() => {
                                    handleSearch();
                                    setStoredPage(1);
                                }}
                            />
                        </TableSearchItem>
                    </TableSearchRow>
                </thead>
                {isFetchingGammes && <TableLoader loading columns={[{ type: 'extra-small' }, { type: 'large' }]} />}
                <tbody>
                    {!isFetchingGammes &&
                        gammes &&
                        gammes.map((ele, indx) => {
                            return (
                                <tr
                                    key={`${ele.id}gammesprod`}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        setActiveGammes((prev) =>
                                            prev.some((gamme) => gamme?.id === ele?.id)
                                                ? prev?.filter((filt) => filt?.id !== ele?.id)
                                                : [...prev, ele]
                                        )
                                    }
                                >
                                    <td style={{ display: 'flex', alignItems: 'centers', width: '20px' }}>
                                        <CheckBox
                                            id={indx}
                                            checked={activeGammes.some((gamme) => gamme?.id === ele?.id)}
                                            style={{ pointerEvent: 'none' }}
                                            // className="role-checkbox"
                                        />
                                        {/* <input type="checkbox" id={indx}></input> */}
                                    </td>
                                    <td>{ele.name}</td>
                                    <td width="75px" />
                                </tr>
                            );
                        })}
                    {gammes?.length > 0 && (
                        <tr>
                            <td colSpan={7}>
                                <div className="sob-v2-table-pagination">
                                    <TablePreviousPage onClick={() => pageChange('prev')} disabled={pageNumber <= 1} />
                                    <TablePage>{pageNumber}</TablePage>
                                    <TableNextPage onClick={() => pageChange('next')} disabled={gammes?.length < 20} />
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {!isFetchingGammes && gammes?.length < 1 && <EmptyData title={t('No results match your search')} />}
        </TableContainer>
    );
}

export default GammesCheck;
