import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Col,
    Container,
    EmptyData,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    notify,
    Row,
    Table,
    TableContainer,
    TableHeader,
    TableHeaderTitle,
    TableLoader,
    Th,
} from '@sobrus-com/sobrus-design-system-v2';
import { axiosGet, axiosPatch, axiosPost } from 'api/API';
import * as yup from 'yup';

const schema = (t) =>
    yup.object({
        name: yup.string().required(t('This field is required')),
    });

function ContactCategory() {
    const [openCreate, setOpenCreate] = useState(false);
    const [fields, setFields] = useState(null);
    const [field, setField] = useState(null);
    const [isFetching, setIsFetching] = useState(true);
    const isSubmitting = useRef(false);
    const { t } = useTranslation();
    const {
        reset,
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema(t)),
        defaultValues: {
            name: '',
        },
    });

    const onSubmit = async (values) => {
        if (!isSubmitting.current) {
            isSubmitting.current = true;
            const data = {
                name: values.name,
            };
            if (field?.id)
                axiosPatch(`contacts/categories/${field?.id}`, data).then((res, err) => {
                    if (res?.data) {
                        notify({
                            position: 'top-center',
                            type: 'success',
                            msg: 'Le champ a été crée avec succès',
                            delay: 5000,
                        });
                        setOpenCreate(false);
                        setIsFetching(true);
                        setField(null);
                        isSubmitting.current = false;
                        reset({
                            name: '',
                        });
                    } else {
                        notify({
                            position: 'top-center',
                            type: 'danger',
                            msg: `Le champ n'a pas été crée, ${res?.data?.msg}`,
                            delay: 5000,
                        });
                        isSubmitting.current = false;
                    }
                });
            else {
                axiosPost(`contacts/categories`, data).then((res, err) => {
                    if (res?.data) {
                        notify({
                            position: 'top-center',
                            type: 'success',
                            msg: 'Le champ a été crée avec succès',
                            delay: 5000,
                        });
                        setOpenCreate(false);
                        setIsFetching(true);
                        isSubmitting.current = false;
                    } else {
                        notify({
                            position: 'top-center',
                            type: 'danger',
                            msg: `Le champ n'a pas été crée, ${res?.data?.msg}`,
                            delay: 5000,
                        });
                        isSubmitting.current = false;
                    }
                });
            }
        }
    };

    useEffect(() => {
        if (isFetching) {
            axiosGet(`contacts/categories`).then((res) => {
                setFields(res?.data);
                setIsFetching(false);
            });
        }
    }, [isFetching]);

    useEffect(() => {
        if (field) {
            reset({
                name: field?.name,
            });
        }
    }, [field]);

    return (
        <section>
            <div className="labs-settings-btns">
                <Button
                    onClick={() => {
                        setOpenCreate(true);
                        reset({
                            name: '',
                        });
                    }}
                >
                    {t('Create contact category')}
                </Button>
            </div>
            <TableContainer>
                <TableHeader>
                    <TableHeaderTitle>{t('Create category')}</TableHeaderTitle>
                </TableHeader>
                <Table>
                    <thead>
                        <tr>
                            <Th hideOrder>{t('Category')}</Th>
                            <th aria-label="empty" width="75px" />
                        </tr>
                    </thead>
                    {isFetching && (
                        <TableLoader loading columns={[{ type: 'large' }, { type: 'extra-small' }, { type: 'dot' }]} />
                    )}
                    <tbody>
                        {!isFetching &&
                            fields &&
                            fields.map((ele) => (
                                <tr>
                                    <td>{ele.name}</td>
                                    <td>
                                        <img
                                            onClick={() => {
                                                setOpenCreate(true);
                                                setField(ele);
                                            }}
                                            src={require('../../../assets/edit-icon.svg').default}
                                            alt=""
                                            role="presentation"
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                    <tfoot>
                        {/* {users?.length > 0 && (
                        <tr>
                            <td colSpan={10}>
                                <div className="sob-v2-table-pagination">
                                    <TablePreviousPage onClick={() => pageChange('prev')} disabled={pageNumber <= 1} />
                                    <TablePage>{pageNumber}</TablePage>
                                    <TableNextPage onClick={() => pageChange('next')} disabled={users?.length < 15} />
                                </div>
                            </td>
                        </tr>
                    )} */}
                        {!isFetching && fields?.length < 1 && (
                            <tr>
                                <td colSpan={20}>
                                    <EmptyData title={t('No results match your search')} />
                                </td>
                            </tr>
                        )}
                    </tfoot>
                </Table>
                {openCreate && (
                    <Modal setOpen={setOpenCreate}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <ModalHeader title={t('Create contact category')} setOpen={setOpenCreate} />
                            <ModalBody style={{ overflow: 'visible' }}>
                                <Container>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label>{t('Category')}*</Label>
                                                <Input
                                                    invalid={errors.name}
                                                    {...register('name')}
                                                    type="text"
                                                    placeholder={t('Category')}
                                                />
                                                <FormFeedback invalid={errors.name}>
                                                    {t('This field is required')}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Container>
                            </ModalBody>
                            <ModalFooter>
                                <Button type="button" color="tertiary" onClick={() => setOpenCreate(false)}>
                                    {t('Cancel')}
                                </Button>
                                <Button type="submit">{t('Save')}</Button>
                            </ModalFooter>
                        </form>
                    </Modal>
                )}
            </TableContainer>
        </section>
    );
}

export default ContactCategory;
