import React from 'react';
import { FiEyeOff } from 'react-icons/fi';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

import dragIcon from './drag.png';

// import "../communStyle.css";
import './DragDrop.scss';

function SobDragDropContext(props) {
    const { className, cssModule, children, onDragEnd, tag: Tag, ...attributes } = props;

    return (
        <DragDropContext onDragEnd={onDragEnd} {...attributes}>
            <div className="sob-drag-row">{children}</div>
        </DragDropContext>
    );
}

const DroppableContainer = React.forwardRef((props, ref) => {
    const { className, cssModule, children, isDraggingOver, title, ...attributes } = props;

    return (
        <div className="sob-droppable-container">
            {title}
            <div
                ref={ref}
                className="sob-droppable-items"
                style={{ backgroundColor: isDraggingOver ? '#f4fcff' : 'white' }}
                {...attributes}
            >
                {children}
            </div>
        </div>
    );
});

DroppableContainer.defaultProps = {
    isDraggingOver: false,
};

function DroppableTitle(props) {
    const { className, cssModule, children, bgColor, textColor, icon, ...attributes } = props;

    return (
        <div style={{ backgroundColor: bgColor }} {...attributes} className="sob-droppable-title-container">
            {icon}
            <p style={{ color: textColor }} className="sob-droppable-title">
                {children}
            </p>
        </div>
    );
}

DroppableTitle.defaultProps = {
    icon: <FiEyeOff color="#F05A29" size={20} />,
    bgColor: 'rgba(240, 90, 41, .06)',
    textColor: '#F05A29',
};

const DraggableItem = React.forwardRef((props, ref) => {
    const { className, disabled, children, isDragging, draggableStyle, ...attributes } = props;

    return (
        <div
            ref={ref}
            {...attributes}
            style={getItemStyle(isDragging, draggableStyle)}
            className={`${disabled ? 'dragable-disabled' : ''}`}
        >
            <img style={{ width: 8, height: 13 }} src={dragIcon} alt="" />
            <p className="sob-draggable-item-text">{children}</p>
        </div>
    );
});

const dragDropReorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const dragDropMove = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

export {
    dragDropMove,
    dragDropReorder,
    Draggable,
    DraggableItem,
    Droppable,
    DroppableContainer,
    DroppableTitle,
    SobDragDropContext,
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 10,
    borderRadius: 8,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? '#E8E8E8' : '#F9F9F9',

    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? 'lightblue' : '#fff',
    padding: grid,
    // width: 250
    border: '1.5px solid #EFEFEF',
    borderRadius: '8px',
    height: 550,
});

// export { SobDragDropContext, Droppable, DroppableContainer, DroppableTitle, Draggable, DraggableItem };
