import React from 'react';

import './EventCard.scss';

function EventCard({ startTime, endTime, eventType, eventTitle, color, ...rest }) {
    return (
        <div className="event-card-container" {...rest}>
            <div className="event-time">
                <em>{startTime}</em> <em>-</em> <em>{endTime}</em>
            </div>
            <div className="event-break" style={color && { backgroundColor: color }} />
            <div className="event-desc">
                <div className="event-type">{eventType}</div>
                <div className="event-title">
                    {eventTitle?.length > 30 ? `${eventTitle?.substring(0, 30)}...` : eventTitle}
                </div>
            </div>
        </div>
    );
}

export default EventCard;
