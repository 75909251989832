import React, { useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiOutlinePlus, HiOutlineTrash } from 'react-icons/hi';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Col,
    Container,
    DashedButton,
    EmptyData,
    FormFeedback,
    FormGroup,
    IconButton,
    Input,
    InputSelect,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    notify,
    RadioButton,
    Row,
    Table,
    TableContainer,
    TableHeader,
    TableHeaderTitle,
    TableLoader,
    Th,
} from '@sobrus-com/sobrus-design-system-v2';
import { axiosGet, axiosPatch, axiosPost } from 'api/API';
import * as yup from 'yup';

const schema = (t) =>
    yup.object({
        name: yup.string().required(t('This field is required')),
        type: yup.object().required(t('This field is required')),
    });

function CustomFields() {
    const [openCreate, setOpenCreate] = useState(false);
    const [fields, setFields] = useState(null);
    const [field, setField] = useState(null);
    const [isFetching, setIsFetching] = useState(true);
    const isSubmitting = useRef(false);
    const id = 1;
    const { t } = useTranslation();
    const {
        reset,
        setValue,
        watch,
        control,
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema(t)),
        defaultValues: {
            name: '',
            type: '',
            fields: [],
            isMandatory: 'true',
        },
    });

    const {
        fields: optionFields,
        append,
        replace,
        remove,
        swap,
        move,
        insert,
    } = useFieldArray({
        control,
        name: 'fields', // unique name for your Field Array
    });

    const type = watch('type');

    const onSubmit = async (values) => {
        if (!isSubmitting.current) {
            isSubmitting.current = true;
            const data = {
                name: values.name,
                type: values.type.value,
                isMandatory: values.isMandatory === 'true',
            };
            if (type?.value !== 'string') {
                data.fields = values.fields.map((ele) => ele.name);
            }
            if (field?.id)
                axiosPatch(`customFields/${field?.id}`, data).then((res, err) => {
                    if (res?.data?.id) {
                        notify({
                            position: 'top-center',
                            type: 'success',
                            msg: 'Le champ a été crée avec succès',
                            delay: 5000,
                        });
                        setOpenCreate(false);
                        setIsFetching(true);
                        setField(null);
                        isSubmitting.current = true;
                        reset({
                            name: '',
                            type: '',
                            isMandatory: 'true',
                        });
                    } else {
                        notify({
                            position: 'top-center',
                            type: 'danger',
                            msg: `Le champ n'a pas été crée, ${res?.data?.msg}`,
                            delay: 5000,
                        });
                        isSubmitting.current = false;
                    }
                });
            else {
                axiosPost(`customFields`, data).then((res, err) => {
                    if (res?.data?.id) {
                        notify({
                            position: 'top-center',
                            type: 'success',
                            msg: 'Le champ a été crée avec succès',
                            delay: 5000,
                        });
                        setOpenCreate(false);
                        setIsFetching(true);
                        isSubmitting.current = true;
                    } else {
                        notify({
                            position: 'top-center',
                            type: 'danger',
                            msg: `Le champ n'a pas été crée, ${res?.data?.msg}`,
                            delay: 5000,
                        });
                        isSubmitting.current = false;
                    }
                });
            }
        }
    };

    useEffect(() => {
        if (isFetching) {
            axiosGet(`customFields`).then((res) => {
                setFields(res?.data);
                setIsFetching(false);
            });
        }
    }, [isFetching]);

    useEffect(() => {
        if (field) {
            reset({
                name: field?.name,
                type: { value: field?.type, label: field?.type },
                isMandatory: field?.isMandatory ? 'true' : 'false',
                fields: [],
            });
            field?.fields && replace(field?.fields?.map((ele) => ({ name: ele })));
        }
    }, [field]);

    const handleClose = () => {
        setOpenCreate(false);
        replace([]);
        reset();
    };

    return (
        <section>
            <div className="labs-settings-btns">
                <Button
                    onClick={() => {
                        setOpenCreate(true);
                        reset({
                            name: '',
                            type: '',
                            isMandatory: 'true',
                        });
                    }}
                >
                    {t('Create Report')}
                </Button>
            </div>
            <TableContainer>
                <TableHeader>
                    <TableHeaderTitle>{t('Custom Fields')}</TableHeaderTitle>
                </TableHeader>
                <Table>
                    <thead>
                        <tr>
                            <Th hideOrder>{t('Custom Fields')}</Th>
                            <Th hideOrder>{t('Type')}</Th>
                            <th aria-label="empty" width="75px" />
                        </tr>
                    </thead>
                    {isFetching && (
                        <TableLoader loading columns={[{ type: 'large' }, { type: 'extra-small' }, { type: 'dot' }]} />
                    )}
                    <tbody>
                        {!isFetching &&
                            fields &&
                            fields.map((ele) => (
                                <tr>
                                    <td>
                                        {ele.name}
                                        {ele?.isMandatory ? '*' : ''}
                                    </td>
                                    <td>{ele.type}</td>
                                    <td>
                                        <img
                                            onClick={() => {
                                                setOpenCreate(true);
                                                setField(ele);
                                                replace(ele.fields);
                                            }}
                                            src={require('../../../assets/edit-icon.svg').default}
                                            alt=""
                                            role="presentation"
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                    <tfoot>
                        {/* {users?.length > 0 && (
                        <tr>
                            <td colSpan={10}>
                                <div className="sob-v2-table-pagination">
                                    <TablePreviousPage onClick={() => pageChange('prev')} disabled={pageNumber <= 1} />
                                    <TablePage>{pageNumber}</TablePage>
                                    <TableNextPage onClick={() => pageChange('next')} disabled={users?.length < 15} />
                                </div>
                            </td>
                        </tr>
                    )} */}
                        {!isFetching && fields?.length < 1 && (
                            <tr>
                                <td colSpan={20}>
                                    <EmptyData title={t('No results match your search')} />
                                </td>
                            </tr>
                        )}
                    </tfoot>
                </Table>
                {openCreate && (
                    <Modal setOpen={handleClose} size="lg">
                        <ModalHeader title="Nouveau champ personnalisé" setOpen={handleClose} />
                        <ModalBody style={{ padding: 20, minHeight: 300 }}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Container>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label>{t('Field Name')}*</Label>
                                                <Input
                                                    invalid={errors.name}
                                                    {...register('name')}
                                                    type="text"
                                                    placeholder={t('Field Name')}
                                                />
                                                <FormFeedback invalid={errors.name}>
                                                    {t('This field is required')}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label>{t('Field Type')}*</Label>
                                                <Controller
                                                    control={control}
                                                    name="type"
                                                    render={({ field: { value, onChange, name } }) => (
                                                        <InputSelect
                                                            invalid={errors.type}
                                                            menuPosition="fixed"
                                                            closeMenuOnScroll={(e) => e.target === document}
                                                            value={value}
                                                            onChange={(e) => {
                                                                onChange(e);
                                                                (e?.value === 'string' || e?.value === 'date') &&
                                                                    replace([]);
                                                            }}
                                                            options={[
                                                                // { value: 'Number', label: 'Number' },
                                                                { value: 'string', label: 'string' },
                                                                { value: 'date', label: 'date' },
                                                                { value: 'selectbox', label: 'selectbox' },
                                                                { value: 'radiobox', label: 'radiobox' },
                                                                { value: 'checkbox', label: 'checkbox' },
                                                            ]}
                                                        />
                                                    )}
                                                />
                                                <FormFeedback invalid={errors.type}>
                                                    {t('This field is required')}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label>{t('Required')}</Label>
                                                <div style={{ display: 'flex' }}>
                                                    <RadioButton text="Oui" value {...register('isMandatory')} />
                                                    <RadioButton
                                                        text="Non"
                                                        style={{ marginTop: 0, marginLeft: 10 }}
                                                        value={false}
                                                        {...register('isMandatory')}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {optionFields.map((ele, index) => (
                                        <Row key={ele.id} style={{ marginBottom: 20 }}>
                                            <Col>
                                                <Controller
                                                    control={control}
                                                    name={`fields.${index}.name`}
                                                    render={({ field: { value, onChange, name } }) => (
                                                        <Input
                                                            // value={value !== ' ' && value}
                                                            value={value}
                                                            onChange={onChange}
                                                            type="text"
                                                            placeholder="Nom du champ"
                                                        />
                                                    )}
                                                />
                                            </Col>
                                            <Col xs="1">
                                                <IconButton
                                                    type="button"
                                                    color="secondary"
                                                    onClick={() => remove(index)}
                                                >
                                                    <HiOutlineTrash size={20} />
                                                </IconButton>
                                            </Col>
                                        </Row>
                                    ))}
                                    {type && type?.value !== 'string' && type?.value !== 'date' && (
                                        <Row>
                                            <Col>
                                                <DashedButton
                                                    type="button"
                                                    style={{ color: '#DF4751' }}
                                                    icon={<HiOutlinePlus color="#DF4751" size={20} />}
                                                    onClick={() => append({ name: '' })}
                                                >
                                                    {' '}
                                                    {t('Add Field of')} {type?.value}
                                                </DashedButton>
                                            </Col>
                                        </Row>
                                    )}
                                </Container>
                            </form>
                        </ModalBody>
                        <ModalFooter>
                            <Button type="button" color="tertiary" onClick={handleClose}>
                                {t('Cancel')}
                            </Button>
                            <Button onClick={handleSubmit(onSubmit)}>{t('Save')}</Button>
                        </ModalFooter>
                    </Modal>
                )}
            </TableContainer>
        </section>
    );
}

export default CustomFields;
