import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Button,
    EmptyData,
    Table,
    TableContainer,
    TableHeader,
    TableHeaderTitle,
    Th,
} from '@sobrus-com/sobrus-design-system-v2';
import BackwardTitle from 'components/backwardTitle/BackwardTitle';
import { format, parseISO } from 'date-fns';

// import { Match, matchPath, useLocation } from "react-router";
import { useAcl } from '../../ACL/acl';
import { axiosPatch } from '../../api/API';
import { UserContext } from '../../context/userContext';

function Notifications() {
    // const [isFetchingPlannings, setIsFetchingPlannings] = useState(true);
    const { notifs, setIsFetchingNotifs } = useContext(UserContext);
    const navigate = useNavigate();
    const acl = useAcl();
    // const match = useMatch("/");
    // const location = useLocation();

    // console.log(location.pathname.split("/"));

    // const getComptes = async (inputValue = null, callback = () => {}) => {
    //     const res = await axiosPost(`comptes/1`, { nom: { value: inputValue, op: "smart" } });
    //     const data = res?.data?.ressources.map((item) => ({ value: item?.id ?? 0, label: item?.nom ?? "" }));
    //     callback(data);
    // };

    const handleNotifClick = (link, id) => {
        setIsFetchingNotifs(true);
        if (acl?.demand?.details) navigate(link);
        else navigate('/access-denied');
        if (acl?.notification?.put_notification_as_seen) axiosPatch(`notifications/${id}/mark-as-seen`);
    };

    const handleMarkAll = () => {
        axiosPatch(`notifications/mark-all-as-seen`).then((res) => setIsFetchingNotifs(true));
    };

    return (
        <section className="products-container labs-container">
            <div className="products-header">
                <BackwardTitle title="Liste des notifications" subTitle="Notifications" />
                <div className="labs-header-btns">
                    {acl?.notification?.list_history && (
                        <Link to="/notifications/history">
                            <Button style={{ margin: 0 }} color="danger-tertiary">
                                Historique
                            </Button>
                        </Link>
                    )}
                    {acl?.notification?.put_all_notifications_as_seen && (
                        <Button onClick={handleMarkAll}>Marquer tout comme lu</Button>
                    )}
                </div>
            </div>
            <TableContainer>
                <TableHeader>
                    <TableHeaderTitle>Liste des notifications</TableHeaderTitle>
                </TableHeader>
                <Table>
                    <thead>
                        <tr>
                            <td style={{ display: 'flex' }} />
                            <Th hideOrder>Notifications</Th>
                            <Th hideOrder>DATE & HEURE</Th>
                            <th aria-label="empty" width="75px" />
                        </tr>
                    </thead>
                    <tbody>
                        {notifs &&
                            notifs.map((ele) => (
                                <tr
                                    key={ele.id}
                                    onClick={() => {
                                        handleNotifClick(ele.link, ele.id);
                                    }}
                                >
                                    <td style={{ display: 'flex', justifyContent: 'center' }}>
                                        {ele.content.includes('affect') && (
                                            <img
                                                style={{ display: 'block' }}
                                                src={require('../../assets/affected-demand.svg').default}
                                                alt=""
                                            />
                                        )}
                                        {ele.content.includes('Nouvelle') && (
                                            <img
                                                style={{ display: 'block' }}
                                                src={require('../../assets/new-demand.svg').default}
                                                alt=""
                                            />
                                        )}
                                        {ele.content.includes('commentaire') && (
                                            <img
                                                style={{ display: 'block' }}
                                                src={require('../../assets/name-mentioned.svg').default}
                                                alt=""
                                            />
                                        )}
                                    </td>
                                    <td>{ele.content}</td>
                                    <td>{ele.createdAt && format(parseISO(ele.createdAt), 'yyyy-MM-dd à HH:mm')}</td>
                                    <td width="75px" />
                                </tr>
                            ))}
                    </tbody>
                </Table>
                {notifs?.length < 1 && <EmptyData title="Vous n'avez aucune notification pour le moment" />}
            </TableContainer>
        </section>
    );
}

export default Notifications;
