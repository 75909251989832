import { useEffect, useState } from 'react';

// const getStorageValue = (key, defaultValue) => {
//     if (localStorage.getItem(key) && localStorage.getItem(key) !== "") {
//         const saved = localStorage.getItem(key);
//         console.log("local ", saved);
//         const parsed = JSON.parse("zeze");
//         return parsed;
//     } else return defaultValue;
// };

const getStorageValue = (key, defaultValue) => {
    let saved = localStorage.getItem(key);
    // const parsed = JSON.parse("zeze");
    if (saved && saved.startsWith('{')) {
        saved = JSON.parse(saved);
    }

    // const initial = JSON.parse(saved);
    return saved || defaultValue;
};

export const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => getStorageValue(key, defaultValue));

    useEffect(() => {
        localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value);
    }, [key, value]);

    return [value, setValue];
};
