import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    EmptyData,
    Input,
    InputSelect,
    SearchBtn,
    Table,
    TableContainer,
    TableHeader,
    TableHeaderActions,
    TableHeaderTitle,
    TableLoader,
    TableNextPage,
    TablePage,
    TablePreviousPage,
    TableRefreshBtn,
    TableSearchBtn,
    TableSearchItem,
    TableSearchRow,
    Th,
} from '@sobrus-com/sobrus-design-system-v2';

import { useAcl } from '../../../ACL/acl';
import { axiosGet, axiosPost } from '../../../api/API';
import { useLocalStorage } from '../../../utils/useLocalStorage';
import usePagination from '../../../utils/usePagination';

function UsersTable() {
    const [isFetchingContacts, setIsFetchingContacts] = useState(true);
    const [users, setUsers] = useState(null);
    const [nompInput, setNompInput] = useLocalStorage('nomUser', '');
    const [prenompInput, setPrenompInput] = useLocalStorage('prenomUser', '');
    const [estAdmin, setEstAdmin] = useLocalStorage('adminUser', '');
    const [estSuper, setEstSuper] = useLocalStorage('SuperUser', '');
    const [orderBy, setOrderBy] = useState(null);
    const [direc, setDirec] = useState(null);
    const [toggle, setToggle] = useState(false);
    const navigate = useNavigate();
    const acl = useAcl();
    const { t } = useTranslation();

    const { pageNumber, pageChange, setStoredPage } = usePagination(setIsFetchingContacts);

    const handleSearch = () => {
        const searchTerm = {};

        if (nompInput !== '') {
            searchTerm.lastName = nompInput;
        }

        if (prenompInput !== '') {
            searchTerm.firstName = prenompInput;
        }

        if (estAdmin?.value) {
            searchTerm.isAdmin = estAdmin?.value;
        }

        if (estSuper?.value) {
            searchTerm.isSupervisor = estSuper?.value;
        }

        setIsFetchingContacts(true);

        return searchTerm;
    };

    const refreshTable = () => {
        setEstAdmin(null);
        setEstSuper(null);
        setNompInput('');
        setPrenompInput('');
        setStoredPage(1);
        setIsFetchingContacts(true);
        setOrderBy(null);
        setDirec(null);
    };

    const handleOrderBy = (by) => {
        setOrderBy(by);
        setIsFetchingContacts(true);
        setDirec((prev) => (prev === 'ASC' ? 'DESC' : 'ASC'));
    };

    useEffect(() => {
        if (isFetchingContacts) {
            const searchTerm = { ...handleSearch(), orderBy, order: direc || 'DESC', page: pageNumber, limit: 15 };
            axiosGet(`users`, searchTerm).then((res) => {
                setUsers(res?.data?.users);
                setIsFetchingContacts(false);
            });
        }
    }, [isFetchingContacts]);

    return (
        <TableContainer className="fade">
            <TableHeader>
                <TableHeaderTitle>{t('User List')}</TableHeaderTitle>
                <TableHeaderActions>
                    <TableRefreshBtn onClick={refreshTable} />
                    <TableSearchBtn onClick={() => setToggle(!toggle)} open={toggle} />
                </TableHeaderActions>
            </TableHeader>
            <Table>
                <thead>
                    <tr>
                        <Th onClick={() => handleOrderBy('lastName')}>{t('Name')}</Th>
                        <Th onClick={() => handleOrderBy('firstName')}>{t('First Name')}</Th>
                        <Th onClick={() => handleOrderBy('isAdmin')}>Est admin</Th>
                        <Th onClick={() => handleOrderBy('isSupervisor')}>Est Superviseur</Th>
                        {(acl?.setting?.user?.update || acl?.setting?.user?.update_access_type) && <Th>Actions</Th>}
                    </tr>
                    <TableSearchRow id="simple" open={toggle}>
                        <TableSearchItem>
                            <Input
                                id="nomp"
                                name="nomp"
                                placeholder={t('Name')}
                                type="text"
                                onChange={(e) => setNompInput(e.target.value)}
                                value={nompInput}
                            />
                        </TableSearchItem>
                        <TableSearchItem>
                            <Input
                                id="pnomp"
                                name="pnomp"
                                placeholder={t('First Name')}
                                type="text"
                                onChange={(e) => setPrenompInput(e.target.value)}
                                value={prenompInput}
                            />
                        </TableSearchItem>
                        <TableSearchItem>
                            <InputSelect
                                placeholder={t('Is Admin')}
                                // invalid={city ? false : true}
                                name="admin"
                                value={estAdmin}
                                options={[
                                    { value: true, label: t('Yes') },
                                    { value: false, label: t('No') },
                                ]}
                                onChange={(e) => setEstAdmin(e || null)}
                            />
                        </TableSearchItem>
                        <TableSearchItem>
                            <InputSelect
                                placeholder={t('Is Supervisor')}
                                name="super"
                                value={estSuper}
                                options={[
                                    { value: true, label: t('Yes') },
                                    { value: false, label: t('No') },
                                ]}
                                onChange={(e) => setEstSuper(e || null)}
                            />
                        </TableSearchItem>
                        <TableSearchItem>
                            <SearchBtn
                                onClick={() => {
                                    handleSearch();
                                    setStoredPage(1);
                                }}
                            />
                        </TableSearchItem>
                    </TableSearchRow>
                </thead>
                {isFetchingContacts && (
                    <TableLoader
                        loading
                        columns={[
                            { type: 'extra-small' },
                            { type: 'extra-small' },
                            { type: 'extra-small' },
                            { type: 'extra-small' },
                            { type: 'dot' },
                        ]}
                    />
                )}
                <tbody>
                    {!isFetchingContacts &&
                        users &&
                        users.map((ele) => (
                            <tr>
                                <th>{ele.lastName}</th>
                                <td>{ele.firstName}</td>
                                <td>{ele.isAdmin ? 'Oui' : 'Non'}</td>
                                <td>{ele.isSupervisor ? 'Oui' : 'Non'}</td>
                                {acl?.setting?.user?.update && (
                                    <td>
                                        <img
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => navigate(`/settings/users/modification/${ele.id}`)}
                                            src={require('../../../assets/edit-icon.svg').default}
                                            alt=""
                                            role="presentation"
                                        />
                                    </td>
                                )}
                            </tr>
                        ))}
                </tbody>
                <tfoot>
                    {users?.length > 0 && (
                        <tr>
                            <td colSpan={10}>
                                <div className="sob-v2-table-pagination">
                                    <TablePreviousPage onClick={() => pageChange('prev')} disabled={pageNumber <= 1} />
                                    <TablePage>{pageNumber}</TablePage>
                                    <TableNextPage onClick={() => pageChange('next')} disabled={users?.length < 15} />
                                </div>
                            </td>
                        </tr>
                    )}
                    {!isFetchingContacts && users?.length < 1 && (
                        <tr>
                            <td colSpan={20}>
                                <EmptyData title={t('No results match your search')} />
                            </td>
                        </tr>
                    )}
                </tfoot>
            </Table>
        </TableContainer>
    );
}

export default UsersTable;
