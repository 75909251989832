import { useContext, useEffect, useRef, useState } from 'react';

import { axiosGet } from '../api/API';
import { UserContext } from '../context/userContext';

const Acl = (action, user) => {
    const aclActions = user?.role?.allowedActions?.map((ele) => ele.name);

    const connectedUserInfos = user;
    if (connectedUserInfos && connectedUserInfos.isAdmin && connectedUserInfos.isAdmin === true) {
        return true;
    }
    const can = aclActions?.some((object) => object === action);
    if (can) {
        return true;
    }
    return false;
};

export const useAcl = (userParam) => {
    const { user } = useContext(UserContext);

    // const [user, setuser] = useState(user);

    useEffect(() => {}, [user]);

    return {
        notification: {
            list: Acl('get_list_of_all_unseen_notifications_with_pagination_and_filters', user),
            list_history: Acl('get_list_of_all_notifications_history_with_pagination_and_filters', user),
            put_notification_as_seen: Acl('get_list_of_all_unseen_notifications_with_pagination_and_filters', user),
            put_all_notifications_as_seen: Acl(
                'get_list_of_all_unseen_notifications_with_pagination_and_filters',
                user
            ),
        },
        demand: {
            list: Acl('get_list_of_demands_with_filters_and_pagination', user),
            add: Acl('add_new_demand', user),
            update: Acl('update_demand', user),
            details: Acl('get_one_demand_details', user),
            disable: Acl('archive_demand', user),
            restore: () => false,
            resolve: Acl('resolve_demand', user),
            assigned: Acl('assign_demand', user),
            comment: Acl('add_comment_to_demand', user),
            subscribe: Acl('subscribe_users_to_demand', user),
            unsubscribe: Acl('unsubscribe_user_from_demand', user),
            sendEmail: Acl('add_response_to_demand', user),
            addModel: Acl('add_demand_response_model', user),
            updateModel: Acl('update_demand_response_model', user),
            detailsModel: Acl('get_single_demand_response_model', user),
            addEmailAddress: Acl('api_app_request_putupdateemailconfig', user),
            listEmailAddress: Acl('api_app_request_getemailconfig', user),
            listModalEmailAddress: Acl('get_demand_response_model_list', user),
        },
        report: {
            home: () => true,
            purchase_orders: () => true,
            contact_by_user: Acl('api_app_rapport_getrapportparcontactcreation', user),
            visits: Acl('api_app_rapport_getrapportparstatut', user),
            visitor_by_channel: Acl('api_app_rapport_getrapportparcanal', user),
            visitor_by_purpose: Acl('api_app_rapport_getrapportparobjectif', user),
            visitor_by_contact_category: Acl('api_app_rapport_getrapportparcontact', user),
            inventory_by_account: Acl('api_app_rapport_getrapportpareventtype', user),
            marketplace: Acl('get_marketplace_report', user),
            posts: Acl('get_posts_report', user),
            visitsbycompetitorandsector: () => true,
        },
        dashboard: {
            list: '/',
        },
        account: {
            list: Acl('get_accounts_list', user),
            details: Acl('get_one_account_details', user),
            add: Acl('add_account', user),
            update: Acl('update_account', user),
            disable: Acl('change_account_status', user),
            restore: Acl('change_account_status', user),
            code: Acl('update_account_code', user),
            contacts: Acl('get_account_contacts_list', user),
            events: Acl('get_accounts_events_list', user),
            photos: Acl('get_list_of_account_photos_with_filters_and_pagination', user),
            remove_category: Acl('remove_account_category', user),
            update_category: Acl('update_account_category', user),
            add_category: Acl('add_account_category', user),
            list_category: Acl('get_account_categories_list', user),
            demands: () => true,
        },
        contact: {
            list: Acl('get_contacts_list', user),
            details: Acl('get_one_contact_details', user),
            add: Acl('add_contact', user),
            disable: Acl('archive_or_restore_contact', user),
            update: Acl('update_contact', user),
            restore: Acl('archive_or_restore_contact', user),
            remove_category: Acl('remove_contact_category', user),
            update_category: Acl('update_contact_category', user),
            add_category: Acl('add_contact_category', user),
            list_category: Acl('get_contact_categories_list', user),
            demands: () => true,
        },
        inventory: {
            list: Acl('api_app_inventaire_getinventaires', user),
            details: Acl('api_app_inventaire_getinventaire', user),
            add: Acl('api_app_inventaire_postinventaire', user),
            update: Acl('api_app_inventaire_putinventaire', user),
            product_list: Acl('api_app_inventaire_getlistdetailinventaire', user),
            update_inventory_product: Acl('api_app_inventaire_insertproduct', user),
            update_state: Acl('api_app_inventaire_putinventairestatut', user),
        },
        calender: {
            list: Acl('get_events_list', user),
            list_month: Acl('get_events_list', user),
            list_week: Acl('get_events_list', user),
            details: Acl('get_one_event_details', user),
            add: Acl('add_event', user),
            update: Acl('update_event', user),
            report: Acl('postpone_event', user),
            disable: Acl('api_app_event_disableevent', user),
            restore: Acl('archive_or_restore_event', user),
        },
        crEvent: {
            list: Acl('api_app_event_getcrevents', user),
            details: Acl('api_app_event_getcrevent', user),
            add: Acl('add_or_update_event_report', user),
            update: Acl('add_or_update_event_report', user),
        },
        gallery: {
            list: Acl('get_list_of_photos_with_filters_and_pagination', user),
            details: Acl('get_one_photo_details', user),
            add: Acl('add_new_photo', user),
            disable: Acl('archive_or_restore_photo', user),
            restore: Acl('archive_or_restore_photo', user),
            update: Acl('update_photo', user),
        },
        product: {
            my_product: Acl('api_app_ressource_postproduitsbylabo', user),
            list: Acl('get_list_of_products_with_filters_and_pagination', user),
            details: Acl('get_one_product_details', user),
            suggestion: {
                list: Acl('get_list_of_products_suggestions_with_filters_and_pagination', user),
                add: Acl('add_new_product_suggestion', user),
                details: Acl('get_one_product_suggestion_details', user),
            },
        },
        resource: {
            list_pharmacies: Acl('api_app_ressource_getpharmacies', user),
            list_healthcareestablishments: Acl('api_app_ressource_getetablissements', user),
            list_associations: Acl('api_app_ressource_getassociations', user),
            pharmacy_details: Acl('api_app_ressource_getpharmacie', user),
            healthcareestablishments_details: Acl('api_app_ressource_getetablissement', user),
            associations_details: Acl('api_app_ressource_getassociation', user),

            suggestion: {
                pharmacy: {
                    list: Acl('get_all_pharmacies', user),
                    add: Acl('add_suggested_pharmacies', user),
                    details: Acl('get_pharmacy_by_id', user),
                    add_suggestion: Acl('add_suggested_pharmacies', user),
                    single_suggestion: Acl('get_suggested_pharmacy_by_id', user),
                    list_suggestion: Acl('get_all_suggested_pharmacies', user),
                },
                healthcareestablishments: {
                    list: Acl('get_list_of_healthcare_establishments_with_filters_and_pagination', user),
                    add: Acl('add_new_healthcare_establishment_suggestion', user),
                    details: Acl('get_one_healthcare_establishment_details', user),
                    add_suggestion: Acl('add_new_healthcare_establishment_suggestion', user),
                    single_suggestion: Acl('get_one_healthcare_establishment_suggestion_details', user),
                    list_suggestion: Acl(
                        'get_list_of_healthcare_establishments_suggestions_with_filters_and_pagination',
                        user
                    ),
                },
                associations: {
                    list: Acl('get_associations_list', user),
                    add: Acl('add_suggested_association', user),
                    details: Acl('get_one_association_details', user),
                    add_suggestion: Acl('add_suggested_association', user),
                    single_suggestion: Acl('get_one_association_suggestion_details', user),
                    list_suggestion: Acl('get_associations_suggestions_list', user),
                },
            },
        },
        planning: {
            list: Acl('get_plannings_list', user),
            details: Acl('get_planning_details', user),
            add: Acl('add_planning', user),
            update: Acl('update_planning', user),
            event_to_planning: Acl('update_events_to_planning', user),
            new_event_to_planning: Acl('create_new_events_to_planning', user),
            delete: Acl('change_planning_status', user),
            restore: Acl('change_planning_status', user),
        },
        setting: {
            user: {
                list: Acl('get_list_of_users_with_filters_and_pagination', user),
                connection_history: Acl('get_list_of_users_authentication_history_with_filters_and_pagination', user),
                affect_role: Acl('api_app_access_setrole', user),
                update: Acl('api_app_access_setrole', user),
                get_access_type: Acl('get_user_access_type', user),
                update_access_type: Acl('update_user_access_type', user),
            },
            email_config: {
                update: Acl('update_email_config', user),
            },
            acl_roles: {
                list: Acl('get_list_of_all_roles_with_pagination_and_filters', user),
                add: Acl('create_a_new_role', user),
                archive: Acl('archive_a_role', user),
                update: Acl('edit_a_role', user),
                affect_methods: Acl('edit_a_role', user),
                defaultRoles: Acl('api_app_access_postrolepardefaut', user),
                getDefaultRoles: Acl('api_app_access_getrolespardefaut', user),
            },
            acl_methods: {
                list: Acl('get_list_of_all_actions_with_pagination_and_filters', user),
                single: Acl('get_one_action_by_its_id', user),
                affect_to_role: Acl('edit_a_role', user),
            },
            product: {
                range: {
                    list: Acl('api_app_ressource_postgammesactives', user),
                    update: Acl('api_app_ressource_insertorupdategammesactives', user),
                },
            },
            performance_report: {
                publications: {
                    details: Acl('get_publications_params', user),
                    add: Acl('add_publications_params', user),
                    history: Acl('get_publications_params_histories_list', user),
                    history_single: Acl('get_single_publications_params_histories', user),
                },
                marketplace: {
                    details: Acl('get_marketplace_params', user),
                    add: Acl('add_marketplace_params', user),
                    history: Acl('get_marketplace_params_histories_list', user),
                    history_single: Acl('get_single_marketplace_params_histories', user),
                },
            },
            custom_fields: {
                list: Acl('get_custom_fields', user),
                add: Acl('add_custom_field', user),
                update: Acl('update_custom_fields', user),
                event_fields: Acl('get_event_report_fields', user),
            },
            displayed_fields: {
                list: Acl('get_displayed_event_report_fields', user),
                add: Acl('add_new_displayed_event_report_fields', user),
            },
            api_integration: {
                list: Acl('get_api_integration_list', user),
                update: Acl('update_api_integration_status', user),
            },
        },
        other: {
            list: Acl('authorized', user),
        },
        publication: {
            list: Acl('get_posts_list', user),
            single: Acl('get_post_details', user),
            add: Acl('add_post', user),
            update: Acl('update_post', user),
            archive: Acl('archive_post', user),
        },
        sales: {
            offers: {
                list: Acl('get_offers_list', user),
                details: Acl('get_one_offer_details', user),
                add: Acl('add_offer', user),
                add_action: Acl('add_action_offer', user),
                complete: Acl('complete_offer', user),
                reopen: Acl('reopen_offer', user),
                archive: Acl('archive_offer', user),
                update: Acl('update_offer', user),
                discontinued: Acl('mark_discontinued_products', user),
                custom_store_add: Acl('add_or_update_custom_store', user),
                custom_stores: Acl('get_custom_store', user),
            },
            orders: {
                list: Acl('get_orders_list', user),
                details: Acl('get_single_order', user),
                add: Acl('add_purchase_order', user),
                status: Acl('change_purchase_order_status', user),
                export: Acl('export_purchase_order', user),
            },
            stores: {
                list: Acl('get_custom_store', user),
                add: Acl('add_or_update_custom_store', user),
            },
        },
        suppliers: { list: Acl('get_list_of_suppliers', user) },
        mobile_version: {
            list: Acl('authorized', user),
        },
        // setuser: setuser,
    };
};

// const aclObject = (user) => {

// };

export default Acl;
