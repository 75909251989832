import { useEffect, useState } from 'react';

const usePagination = (setFetching, storageName) => {
    const [pageNumber, setPageNumber] = useState(localStorage.getItem(storageName) || 1);

    useEffect(() => {
        const getPage = localStorage.getItem(storageName);
        if (getPage) {
            setPageNumber(Number(getPage));
        } else if (storageName) {
            localStorage.setItem(storageName, 1);
        }
    }, [pageNumber]);

    const setStoredPage = (num) => {
        setPageNumber(num);
        if (storageName) localStorage.setItem(storageName, num);
    };

    const pageChange = (dir) => {
        if (dir === 'prev' && pageNumber > 1) {
            setStoredPage(pageNumber - 1);
            setFetching(true);
        } else {
            setStoredPage(pageNumber + 1);
            setFetching(true);
        }
    };
    return { pageNumber, pageChange, setStoredPage };
};

export default usePagination;
