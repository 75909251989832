import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
    Button,
    EmptyData,
    Table,
    TableContainer,
    TableHeader,
    TableHeaderTitle,
    TableLoader,
    TableNextPage,
    TablePage,
    TablePreviousPage,
    Tag,
    Th,
} from '@sobrus-com/sobrus-design-system-v2';

import { useAcl } from '../../../ACL/acl';
import { axiosGet, axiosPost } from '../../../api/API';
import usePagination from '../../../utils/usePagination';

function EmailOut() {
    const [isFetchingEmails, setIsFetchingEmails] = useState(true);
    const [emails, setEmails] = useState(null);
    const navigate = useNavigate();
    const acl = useAcl();
    const { t } = useTranslation();

    const { pageNumber, pageChange, setPageNumber } = usePagination(setIsFetchingEmails);

    useEffect(() => {
        if (isFetchingEmails) {
            axiosGet(`demands/responses/models`).then((res) => {
                setEmails(res?.data);
                setIsFetchingEmails(false);
            });
        }
    }, [isFetchingEmails]);

    return (
        <TableContainer className="fade">
            <div className="labs-settings-btns">
                {acl?.demand?.addModel && (
                    <Link to="/settings/emails-out/modele-creation">
                        <Button>{t('New Template')}</Button>
                    </Link>
                )}
            </div>
            <TableHeader>
                <TableHeaderTitle>{t('Email Templates')}</TableHeaderTitle>
            </TableHeader>
            <Table>
                <thead>
                    <tr>
                        <Th hideOrder>{t('Name')}</Th>
                        <Th hideOrder>{t('Status')}</Th>
                        {acl?.demand?.updateModel && <Th hideOrder>{t('Action')}</Th>}
                        <th aria-label="empty" width="75px" />
                    </tr>
                </thead>
                {isFetchingEmails && <TableLoader loading columns={[{ type: 'large' }, { type: 'extra-small' }]} />}
                <tbody>
                    {!isFetchingEmails &&
                        emails &&
                        emails.map((ele) => (
                            <tr key={`${ele.id}emailsModel`}>
                                <td>{ele.name}</td>
                                <td>
                                    {ele.status === true ? (
                                        <Tag bgColor="#EDFAF9" textColor="#1BBAAC">
                                            {t('Active')}
                                        </Tag>
                                    ) : (
                                        <Tag bgColor="#FFF5F2" textColor="#F05A29">
                                            {t('Inactive')}
                                        </Tag>
                                    )}
                                </td>
                                {acl?.demand?.updateModel && (
                                    <td>
                                        <img
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => navigate(`/settings/emails-out/modele-creation/${ele.id}`)}
                                            src={require('../../../assets/edit-icon.svg').default}
                                            alt=""
                                            role="presentation"
                                        />
                                    </td>
                                )}
                                <td width="75px" />
                            </tr>
                        ))}
                    {emails?.length > 0 && (
                        <tr>
                            <td colSpan={7}>
                                <div className="sob-v2-table-pagination">
                                    <TablePreviousPage onClick={() => pageChange('prev')} disabled={pageNumber <= 1} />
                                    <TablePage>{pageNumber}</TablePage>
                                    <TableNextPage onClick={() => pageChange('next')} disabled={emails?.length < 20} />
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {!isFetchingEmails && emails?.length < 1 && <EmptyData title={t('No results match your search')} />}
        </TableContainer>
    );
}

export default EmailOut;
