import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TableContainer, TableHeader, TableHeaderTitle } from '@sobrus-com/sobrus-design-system-v2';
import BackwardTitle from 'components/backwardTitle/BackwardTitle';
import useTitle from 'utils/useTitle';

import { useAcl } from '../../ACL/acl';
import AccordionMenu from '../../components/accordionMenu/AccordionMenu';

function Rapports() {
    const acl = useAcl();
    const { t } = useTranslation();

    useTitle(t('Reports'));

    return (
        <section className="products-container labs-container fade">
            <div className="products-header">
                <BackwardTitle title={t('Reports List')} subTitle={t('Reports')} />
            </div>
            <TableContainer>
                <TableHeader>
                    <TableHeaderTitle>{t('Reports List')}</TableHeaderTitle>
                </TableHeader>
                <div className="rapports-container">
                    <div>
                        <AccordionMenu title={t('Order Reports')} number="1" disabled>
                            {/* <Link to="/reports/event-per-canal">Rapport des événements</Link>
                        <Link to="/reports/event-per-canal">Rapport des événements</Link>
                        <Link to="/reports/event-per-canal">Rapport des événements</Link> */}
                        </AccordionMenu>
                        <AccordionMenu title={t('Competition Reports')} number="3" disabled>
                            {/* <Link to="/reports/event-per-canal">Rapport des événements</Link>
                        <Link to="/reports/event-per-canal">Rapport des événements</Link>
                        <Link to="/reports/event-per-canal">Rapport des événements</Link> */}
                        </AccordionMenu>
                        <AccordionMenu title={t('Contact Reports')} number="5" disabled={!acl?.report?.contact_by_user}>
                            {acl?.report?.contact_by_user && (
                                <Link to="/reports/contact-per-user">{t('Contact Reports by User')}</Link>
                            )}
                        </AccordionMenu>
                    </div>
                    <div>
                        <AccordionMenu
                            title={t('Event Reports')}
                            number="2"
                            disabled={
                                !acl?.report?.visits &&
                                !acl?.report?.visitor_by_channel &&
                                !acl?.report?.visitor_by_purpose &&
                                !acl?.report?.visitor_by_contact_category
                            }
                        >
                            {acl?.report?.visits && (
                                <Link to="/reports/event-general">{t('General Event Report')}</Link>
                            )}
                            {acl?.report?.visitor_by_channel && (
                                <Link to="/reports/event-per-canal">{t('Events by Visitor/Channel')}</Link>
                            )}
                            {acl?.report?.visitor_by_purpose && (
                                <Link to="/reports/event-per-objectif">{t('Events by Visitor/Event Objective')}</Link>
                            )}
                            {acl?.report?.visitor_by_contact_category && (
                                <Link to="/reports/event-per-categorie">{t('Events by Visitor/Contact Category')}</Link>
                            )}
                        </AccordionMenu>
                        <AccordionMenu title={t('Inventory Reports')} number="4" disabled>
                            {/* <Link to="/reports/event-per-canal">Rapport des événements</Link>
                        <Link to="/reports/event-per-canal">Rapport des événements</Link>
                        <Link to="/reports/event-per-canal">Rapport des événements</Link> */}
                        </AccordionMenu>
                    </div>
                </div>
            </TableContainer>
        </section>
    );
}

export default Rapports;
