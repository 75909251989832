/* eslint-disable no-useless-concat */
/* eslint-disable no-console */
import axios from 'axios';
import Cookies from 'js-cookie';

import { API_URL } from '../env';

export const axiosGet = async (suffix, params) => {
    try {
        const response = await axios.get(API_URL + suffix, { withCredentials: true, params });
        const data = await response;
        return data;
    } catch (error) {
        console.error(error);
        if (
            (error.response?.data?.message === 'code1' || error.response?.data?.message === 'code2') &&
            suffix !== 'authenticatedUser'
        ) {
            localStorage.removeItem('isLogin'); // 401 402 403 410
            window.location.reload();
        }
        if (error?.response?.status === 404) {
            // <Navigate to="/404" replace={true} />;
            window.location.replace('/404');
        }
        return error;
    }
};

export const axiosPost = async (suffix, body, headers, responseType) => {
    try {
        const response = await axios.post(API_URL + suffix, body, { withCredentials: true, headers, responseType });
        if (!headers) {
            const data = await response;
            return data;
        }
        return response;
    } catch (error) {
        console.error(error);
        if (error.response?.data?.message === 'code1' && suffix !== 'authenticatedUser') {
            localStorage.removeItem('isLogin');
            window.location.reload();
        }
        return error;
    }
};

export const axiosPut = async (suffix, body, headers) => {
    try {
        const response = await axios.put(API_URL + suffix, body, { withCredentials: true, headers });
        if (!headers) {
            const data = await response;
            return data;
        }
        return response;
    } catch (error) {
        console.error(error);
        if (error.response?.data?.message === 'code1' && suffix !== 'authenticatedUser') {
            localStorage.removeItem('isLogin');
            window.location.reload();
        }
        return error;
    }
};

export const axiosPatch = async (suffix, body, headers) => {
    try {
        const response = await axios.patch(API_URL + suffix, body, { withCredentials: true, headers });
        if (!headers) {
            const data = await response;
            return data;
        }
        return response;
    } catch (error) {
        console.error(error);
        if (error.response?.data?.message === 'code1' && suffix !== 'authenticatedUser') {
            localStorage.removeItem('isLogin');
            window.location.reload();
        }
        return error;
    }
};

export const axiosDelete = async (suffix, body, headers) => {
    try {
        const response = await axios.delete(API_URL + suffix, { withCredentials: true, headers });
        if (!headers) {
            const data = await response;
            return data;
        }
        return response;
    } catch (error) {
        console.error(error);
        if (error.response?.data?.message === 'code1' && suffix !== 'authenticatedUser') {
            localStorage.removeItem('isLogin');
            window.location.reload();
        }
        return error;
    }
};
