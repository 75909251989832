import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigComponent } from '@sobrus-com/sobrus-design-system-v2';

import './i18n';

import App from './App';
import reportWebVitals from './reportWebVitals';

import '@sobrus-com/sobrus-design-system-v2/dist/style.css';
import '@sobrus-com/sobrus-surveys-package/dist/style.css';
import '@sobrus-com/sobrus-calendar/dist/style.css';
import '@sobrus-com/sobrus-posts-package/dist/style.css';
import './index.css';

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <ConfigComponent
            colors={{
                primary: '#DF4751',
                primaryHover: '#CF424C',
                secondary: '#FBE9EA',
                secondaryHover: '#F0D4D6',
            }}
        >
            <Suspense fallback="">
                <App />
            </Suspense>
        </ConfigComponent>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
