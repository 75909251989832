import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    EmptyData,
    Table,
    TableContainer,
    TableHeader,
    TableHeaderTitle,
    TableNextPage,
    TablePage,
    TablePreviousPage,
    Th,
} from '@sobrus-com/sobrus-design-system-v2';
import BackwardTitle from 'components/backwardTitle/BackwardTitle';
import { format, parseISO } from 'date-fns';

import { axiosGet } from '../../api/API';
import usePagination from '../../utils/usePagination';

function NotifsHistoric() {
    const [isFetchingNotifs, setIsFetchingNotifs] = useState(true);
    const [notifs, setNotifs] = useState(null);
    const navigate = useNavigate();

    const { pageNumber, pageChange, setPageNumber } = usePagination(setIsFetchingNotifs);

    useEffect(() => {
        if (isFetchingNotifs) {
            axiosGet(`notifications/history`, { page: pageNumber, limit: 20 }).then((res) => {
                setNotifs(res?.data?.notifications);
                setIsFetchingNotifs(false);
            });
        }
    }, [isFetchingNotifs]);

    return (
        <section className="products-container labs-container">
            <div className="products-header">
                <BackwardTitle
                    title="Historique notifications"
                    subTitle="Liste des notifications"
                    to="/notifications"
                    isBackward
                />
            </div>
            <TableContainer>
                <TableHeader>
                    <TableHeaderTitle>Liste des notifications</TableHeaderTitle>
                </TableHeader>
                <Table>
                    <thead>
                        <tr>
                            <td style={{ display: 'flex' }} />
                            <Th hideOrder>Notifications</Th>
                            <Th hideOrder>DATE & HEURE</Th>
                            <th aria-label="empty" width="75px" />
                        </tr>
                    </thead>
                    <tbody>
                        {notifs &&
                            notifs.map((ele) => (
                                <tr key={ele.id} onClick={() => navigate(ele.link)}>
                                    <td style={{ display: 'flex', justifyContent: 'center' }}>
                                        {ele.content.includes('affect') && (
                                            <img
                                                style={{ display: 'block' }}
                                                src={require('../../assets/affected-demand.svg').default}
                                                alt=""
                                            />
                                        )}
                                        {ele.content.includes('Nouvelle') && (
                                            <img
                                                style={{ display: 'block' }}
                                                src={require('../../assets/new-demand.svg').default}
                                                alt=""
                                            />
                                        )}
                                        {ele.content.includes('commentaire') && (
                                            <img
                                                style={{ display: 'block' }}
                                                src={require('../../assets/name-mentioned.svg').default}
                                                alt=""
                                            />
                                        )}
                                    </td>
                                    <td>{ele.content}</td>
                                    <td>{ele.createdAt && format(parseISO(ele.createdAt), 'yyyy-MM-dd à HH:mm')}</td>
                                    <td width="75px" />
                                </tr>
                            ))}
                        {notifs?.length > 0 && (
                            <tr>
                                <td colSpan={7}>
                                    <div className="sob-v2-table-pagination">
                                        <TablePreviousPage
                                            onClick={() => pageChange('prev')}
                                            disabled={pageNumber <= 1}
                                        />
                                        <TablePage>{pageNumber}</TablePage>
                                        <TableNextPage
                                            onClick={() => pageChange('next')}
                                            disabled={notifs?.length < 20}
                                        />
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                {!isFetchingNotifs && notifs?.length < 1 && (
                    <EmptyData title="Vous n'avez aucune historique pour le moment" />
                )}
            </TableContainer>
        </section>
    );
}

export default NotifsHistoric;
