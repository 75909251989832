import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router';
import { Button, SupportBtn } from '@sobrus-com/sobrus-design-system-v2';

import { UserContext } from '../context/userContext';
import { AUTH_URL } from '../env';

function Signin() {
    const { isLogin } = useContext(UserContext);
    const { t } = useTranslation();

    // console.log(isLogin);

    if (!isLogin) {
        return (
            <section className="signin-wrapper fade">
                <header className="signin-header">
                    <img src={require('../assets/logo.svg').default} alt="" />
                    <SupportBtn
                        data={{
                            clientRef: 'ma-3948-35523-rr',
                            email: 'support@sobrus.com',
                            phoneNumber: '05 30 500 500',
                            showEmail: true,
                        }}
                        position="bottom right"
                    />
                </header>
                <div className="signin-container">
                    <img src={require('../assets/logo.svg').default} alt="" />
                    <a href={AUTH_URL}>
                        <Button href="" color="primary">
                            {t('Sign in')}
                        </Button>
                    </a>
                </div>
            </section>
        );
    }
    return <Navigate to="/" />;
}

export default Signin;
