import React, { useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';

function AccordionMenu({ children, title, number, disabled = false }) {
    const [selected, setSelected] = useState(false);

    return (
        <Accordion
            className="accord-links"
            allowMultipleExpanded
            allowZeroExpanded
            aria-selected={selected}
            onClick={() => setSelected(!selected)}
            aria-disabled={disabled}
        >
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        <div className="accord-number">{number}</div>
                        <div className="accord-title">{title}</div>
                        {!selected && <img src={require('../../assets/accord-arrow.svg').default} alt="" />}
                        {selected && <img src={require('../../assets/accord-arrow-down.svg').default} alt="" />}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <ul>{children}</ul>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
    );
}

export default AccordionMenu;
