import './emailPublication.scss';

function EmailPublication() {
    return (
        <table
            className="container-table"
            style={{
                boxSizing: 'border-box',
                fontFamily: 'arial, helvetica, sans-serif',
                maxWidth: '600px',
                width: '100%',
            }}
            role="presentation"
            cellPadding="0"
            cellSpacing="0"
            width="100%"
            border="0"
        >
            <tr>
                <td style={{ textAlign: 'left' }}>
                    <img className="logo" src={require('./logo.png')} alt="logo" />
                </td>
                <td style={{ fontSize: '0.9rem', color: '#707070;', textAlign: 'right' }}>21 Oct 2022</td>
            </tr>
            <tr>
                <td colSpan="2" style={{ textAlign: 'center', padding: '30px 0', color: '#d11c33' }}>
                    <h1
                        style={{
                            textAlign: 'center',
                            margin: 0,
                            backgroundColor: '#fef7f8',
                            borderRadius: 27,
                            padding: '40px 0',
                            fontSize: '1.8rem',
                            fontWeight: 700,
                        }}
                    >
                        Les performances de votre
                        <br />
                        campagne publicitaire
                    </h1>
                </td>
            </tr>
            <tr>
                <td colSpan="2">
                    <table
                        className="container-table-cards"
                        style={{
                            fontFamily: 'arial, helvetica, sans-serif',
                            width: '100%',
                            backgroundColor: '#fef7f8',
                            borderRadius: '27px',
                        }}
                        role="presentation"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                        border="0"
                    >
                        <tbody>
                            <tr>
                                <td
                                    colSpan="2"
                                    style={{ backgroundColor: '#fff', borderRadius: '27px', padding: '18px' }}
                                >
                                    <h3
                                        style={{
                                            fontSize: '1.3rem',
                                            fontWeight: 600,
                                            color: '#d11c33',
                                            margin: '10px 0',
                                        }}
                                    >
                                        Nom de la campagne
                                    </h3>
                                    {/* <p style="margin: 0" /> */}
                                    <table
                                        style={{
                                            fontFamily: 'arial, helvetica, sans-serif',
                                            maxWidth: '600px',
                                            width: '100%',
                                        }}
                                        role="presentation"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        width="100%"
                                        border="0"
                                    >
                                        <tr style={{ textAlign: 'center' }}>
                                            <td className="innerTable-col" style={{ width: '50%' }}>
                                                <h4
                                                    style={{
                                                        fontSize: '1.3rem',
                                                        fontWeight: 600,
                                                        color: '#d11c33',
                                                        margin: '10px 0',
                                                    }}
                                                >
                                                    Date du début
                                                </h4>
                                                <p style={{ margin: 0, fontSize: '0.9rem' }}>21/02/2022</p>
                                            </td>
                                            <td className="innerTable-col" style={{ width: '50%' }}>
                                                <h4
                                                    style={{
                                                        fontSize: '1.3rem',
                                                        fontWeight: 600,
                                                        color: '#d11c33',
                                                        margin: '10px 0',
                                                    }}
                                                >
                                                    Date de fin
                                                </h4>
                                                <p style={{ margin: 0, fontSize: '0.9rem' }}>21/02/2022</p>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: '25px 0', width: '50%' }}>
                                    <table
                                        style={{
                                            fontFamily: 'arial, helvetica, sans-serif',
                                            maxWidth: '600px',
                                            width: '100%',
                                            paddingRight: '10px',
                                        }}
                                        role="presentation"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        width="100%"
                                        border="0"
                                    >
                                        <tr>
                                            <td
                                                style={{
                                                    backgroundColor: '#fff',
                                                    borderRadius: '27px',
                                                    padding: '8px',
                                                    marginRight: '20px',
                                                }}
                                            >
                                                <table className="table-card">
                                                    <tr>
                                                        <td>
                                                            <img src={require('./view-icon.png')} alt="time" />
                                                        </td>
                                                        <td>
                                                            <h4
                                                                style={{
                                                                    fontSize: '1.3rem',
                                                                    fontWeight: 600,
                                                                    color: '#d11c33',
                                                                    margin: 0,
                                                                }}
                                                            >
                                                                205
                                                            </h4>
                                                            <p style={{ margin: 0, fontSize: '0.9rem' }}>Total vues</p>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td style={{ padding: '25px 0', width: '50%' }}>
                                    <table
                                        style={{
                                            fontFamily: 'arial, helvetica, sans-serif',
                                            maxWidth: '600px',
                                            width: '100%',
                                            paddingLeft: '10px',
                                        }}
                                        role="presentation"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        width="100%"
                                        border="0"
                                    >
                                        <tr>
                                            <td
                                                style={{
                                                    backgroundColor: '#fff',
                                                    borderRadius: '27px',
                                                    padding: '8px',
                                                    marginRight: '20px',
                                                }}
                                            >
                                                <table className="table-card">
                                                    <tr>
                                                        <td>
                                                            <img src={require('./viewunique-icon.png')} alt="time" />
                                                        </td>
                                                        <td>
                                                            <h4
                                                                style={{
                                                                    fontSize: '1.3rem',
                                                                    fontWeight: 600,
                                                                    color: '#d11c33',
                                                                    margin: 0,
                                                                }}
                                                            >
                                                                455
                                                            </h4>
                                                            <p style={{ margin: 0, fontSize: '0.9rem' }}>
                                                                Total vues uniques
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '50%' }}>
                                    <table
                                        style={{
                                            fontFamily: 'arial, helvetica, sans-serif',
                                            maxWidth: '600px',
                                            width: '100%',
                                            paddingRight: '10px',
                                        }}
                                        role="presentation"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        width="100%"
                                        border="0"
                                    >
                                        <tr>
                                            <td
                                                style={{
                                                    backgroundColor: '#fff',
                                                    borderRadius: '27px',
                                                    padding: '8px',
                                                    marginRight: '20px',
                                                }}
                                            >
                                                <table className="table-card">
                                                    <tr>
                                                        <td>
                                                            <img src={require('./click-icon.png')} alt="time" />
                                                        </td>
                                                        <td>
                                                            <h4
                                                                style={{
                                                                    fontSize: '1.3rem',
                                                                    fontWeight: 600,
                                                                    color: '#d11c33',
                                                                    margin: 0,
                                                                }}
                                                            >
                                                                50
                                                            </h4>
                                                            <p style={{ margin: 0, fontSize: '0.9rem' }}>
                                                                Total cliques
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td style={{ width: '50%' }}>
                                    <table
                                        style={{
                                            fontFamily: 'arial, helvetica, sans-serif',
                                            maxWidth: '600px',
                                            width: '100%',
                                            paddingLeft: '10px',
                                        }}
                                        role="presentation"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        width="100%"
                                        border="0"
                                    >
                                        <tr>
                                            <td
                                                style={{
                                                    backgroundColor: '#fff',
                                                    borderRadius: '27px',
                                                    padding: '8px',
                                                    marginRight: '20px',
                                                }}
                                            >
                                                <table className="table-card">
                                                    <tr>
                                                        <td>
                                                            <img src={require('./clickunique-icon.png')} alt="time" />
                                                        </td>
                                                        <td>
                                                            <h4
                                                                style={{
                                                                    fontSize: '1.3rem',
                                                                    fontWeight: 600,
                                                                    color: '#d11c33',
                                                                    margin: 0,
                                                                }}
                                                            >
                                                                20
                                                            </h4>
                                                            <p style={{ margin: 0, fontSize: '0.9rem' }}>
                                                                Total cliques uniques
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{ padding: '15px 0', textAlign: 'center' }}>
                                    <h3 style={{ fontSize: '1.1rem', fontWeight: 600, margin: 20 }}>
                                        Satisfait des performances de votre campagne?
                                    </h3>
                                    <a
                                        style={{
                                            padding: '15px 25px',
                                            backgroundColor: '#d11c33',
                                            color: '#fff',
                                            marginTop: 10,
                                            borderRadius: 10,
                                            textDecoration: 'none',
                                            fontSize: '1.1rem',
                                        }}
                                        href="https://labs.sobrus.ovh/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Planifiez une autre campagne
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </table>
    );
}

export default EmailPublication;
