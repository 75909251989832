import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';
import { NavLink } from 'react-router-dom';
import {
    SettingsAccordion,
    SettingsAccordionItem,
    SettingsAccordionItemButton,
    SettingsAccordionItemHeading,
    SettingsAccordionPanel,
    SettingsAccordionPanelItem,
} from '@sobrus-com/sobrus-design-system-v2';
import BackwardTitle from 'components/backwardTitle/BackwardTitle';
import { PASSWORD_URL } from 'env';
import useTitle from 'utils/useTitle';

import { useAcl } from '../../ACL/acl';

function Settings() {
    const acl = useAcl();
    const { t } = useTranslation();

    useTitle(t('Settings'));

    return (
        <section className="products-container labs-container fade">
            <div className="products-header">
                <BackwardTitle title={t('My Settings')} subTitle={t('Settings')} />
            </div>
            <div className="settings-container">
                <SettingsAccordion style={{ maxWidth: '300px' }}>
                    <SettingsAccordionItem uuid="abcd">
                        <SettingsAccordionItemHeading>
                            <SettingsAccordionItemButton
                                icon={<img src={require('assets/settings/mysettings.svg').default} alt="" />}
                            >
                                {t('My Settings')}
                            </SettingsAccordionItemButton>
                        </SettingsAccordionItemHeading>
                        <SettingsAccordionPanel>
                            <SettingsAccordionPanelItem>
                                <NavLink
                                    className={(navData) =>
                                        navData.isActive
                                            ? 'sob-accordion__panel_item active'
                                            : 'sob-accordion__panel_item'
                                    }
                                    to="/settings/profil"
                                >
                                    {t('Profile')}
                                </NavLink>
                            </SettingsAccordionPanelItem>
                            <SettingsAccordionPanelItem>
                                <a
                                    className="sob-accordion__panel_item"
                                    href={PASSWORD_URL}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t('Change My Password')}
                                </a>
                            </SettingsAccordionPanelItem>
                        </SettingsAccordionPanel>
                    </SettingsAccordionItem>
                    {acl?.setting.user.list && acl?.setting.user.connection_history && (
                        <SettingsAccordionItem>
                            <SettingsAccordionItemHeading>
                                <SettingsAccordionItemButton
                                    icon={<img src={require('assets/settings/profilicon.svg').default} alt="" />}
                                >
                                    {t('Users')}
                                </SettingsAccordionItemButton>
                            </SettingsAccordionItemHeading>
                            <SettingsAccordionPanel>
                                {acl?.setting.user.list && (
                                    <SettingsAccordionPanelItem>
                                        <NavLink
                                            className={(navData) =>
                                                navData.isActive
                                                    ? 'sob-accordion__panel_item active'
                                                    : 'sob-accordion__panel_item'
                                            }
                                            to="/settings/users"
                                        >
                                            {t('User List')}
                                        </NavLink>
                                    </SettingsAccordionPanelItem>
                                )}
                                {acl?.setting.user.connection_history && (
                                    <SettingsAccordionPanelItem>
                                        <NavLink
                                            className={(navData) =>
                                                navData.isActive
                                                    ? 'sob-accordion__panel_item active'
                                                    : 'sob-accordion__panel_item'
                                            }
                                            to="/settings/connections-history"
                                        >
                                            {t('Login History')}
                                        </NavLink>
                                    </SettingsAccordionPanelItem>
                                )}
                            </SettingsAccordionPanel>
                        </SettingsAccordionItem>
                    )}
                    {acl?.demand?.listEmailAddress && acl?.demand?.listModalEmailAddress && (
                        <SettingsAccordionItem>
                            <SettingsAccordionItemHeading>
                                <SettingsAccordionItemButton
                                    icon={<img src={require('assets/settings/email.svg').default} alt="" />}
                                >
                                    {t('Email Settings')}
                                </SettingsAccordionItemButton>
                            </SettingsAccordionItemHeading>
                            <SettingsAccordionPanel>
                                {acl?.demand?.listEmailAddress && (
                                    <SettingsAccordionPanelItem>
                                        <NavLink
                                            className={(navData) =>
                                                navData.isActive
                                                    ? 'sob-accordion__panel_item active'
                                                    : 'sob-accordion__panel_item'
                                            }
                                            to="/settings/emails-in"
                                        >
                                            {t('Incoming Emails')}
                                        </NavLink>
                                    </SettingsAccordionPanelItem>
                                )}
                                {acl?.demand?.listModalEmailAddress && (
                                    <SettingsAccordionPanelItem>
                                        <NavLink
                                            className={(navData) =>
                                                navData.isActive
                                                    ? 'sob-accordion__panel_item active'
                                                    : 'sob-accordion__panel_item'
                                            }
                                            to="/settings/emails-out"
                                        >
                                            {t('Email Templates')}
                                        </NavLink>
                                    </SettingsAccordionPanelItem>
                                )}
                            </SettingsAccordionPanel>
                        </SettingsAccordionItem>
                    )}
                    {acl?.setting?.product?.range?.list && (
                        <SettingsAccordionItem>
                            <SettingsAccordionItemHeading>
                                <SettingsAccordionItemButton
                                    icon={<img src={require('assets/settings/package.svg').default} alt="" />}
                                >
                                    {t('Product Settings')}
                                </SettingsAccordionItemButton>
                            </SettingsAccordionItemHeading>
                            <SettingsAccordionPanel>
                                <SettingsAccordionPanelItem>
                                    <NavLink
                                        className={(navData) =>
                                            navData.isActive
                                                ? 'sob-accordion__panel_item active'
                                                : 'sob-accordion__panel_item'
                                        }
                                        to="/settings/gammes"
                                    >
                                        {t('Active Product Ranges')}
                                    </NavLink>
                                </SettingsAccordionPanelItem>
                            </SettingsAccordionPanel>
                        </SettingsAccordionItem>
                    )}
                    {acl?.setting.acl_roles.list && acl?.setting.acl_methods.list && (
                        <SettingsAccordionItem>
                            <SettingsAccordionItemHeading>
                                <SettingsAccordionItemButton
                                    icon={<img src={require('assets/settings/acl.svg').default} alt="" />}
                                >
                                    ACL
                                </SettingsAccordionItemButton>
                            </SettingsAccordionItemHeading>
                            <SettingsAccordionPanel>
                                {acl?.setting.acl_roles.list && (
                                    <SettingsAccordionPanelItem>
                                        <NavLink
                                            className={(navData) =>
                                                navData.isActive
                                                    ? 'sob-accordion__panel_item active'
                                                    : 'sob-accordion__panel_item'
                                            }
                                            to="/settings/roles"
                                        >
                                            {t('Roles')}
                                        </NavLink>
                                    </SettingsAccordionPanelItem>
                                )}
                                {acl?.setting.acl_methods.list && (
                                    <SettingsAccordionPanelItem>
                                        <NavLink
                                            className={(navData) =>
                                                navData.isActive
                                                    ? 'sob-accordion__panel_item active'
                                                    : 'sob-accordion__panel_item'
                                            }
                                            to="/settings/methodes"
                                        >
                                            {t('Methods')}
                                        </NavLink>
                                    </SettingsAccordionPanelItem>
                                )}
                            </SettingsAccordionPanel>
                        </SettingsAccordionItem>
                    )}
                    <SettingsAccordionItem>
                        <SettingsAccordionItemHeading>
                            <SettingsAccordionItemButton
                                icon={<img src={require('assets/settings/account.svg').default} alt="" />}
                            >
                                {t('Account Settings')}
                            </SettingsAccordionItemButton>
                        </SettingsAccordionItemHeading>
                        <SettingsAccordionPanel>
                            <SettingsAccordionPanelItem>
                                <NavLink
                                    className={(navData) =>
                                        navData.isActive
                                            ? 'sob-accordion__panel_item active'
                                            : 'sob-accordion__panel_item'
                                    }
                                    to="/settings/accountCategory"
                                >
                                    {t('Account Categories')}
                                </NavLink>
                            </SettingsAccordionPanelItem>
                        </SettingsAccordionPanel>
                    </SettingsAccordionItem>
                    <SettingsAccordionItem>
                        <SettingsAccordionItemHeading>
                            <SettingsAccordionItemButton
                                icon={<img src={require('assets/settings/users.svg').default} alt="" />}
                            >
                                {t('Contact Settings')}
                            </SettingsAccordionItemButton>
                        </SettingsAccordionItemHeading>
                        <SettingsAccordionPanel>
                            <SettingsAccordionPanelItem>
                                <NavLink
                                    className={(navData) =>
                                        navData.isActive
                                            ? 'sob-accordion__panel_item active'
                                            : 'sob-accordion__panel_item'
                                    }
                                    to="/settings/contactCategory"
                                >
                                    {t('Contact Categories')}
                                </NavLink>
                            </SettingsAccordionPanelItem>
                        </SettingsAccordionPanel>
                    </SettingsAccordionItem>
                    {acl?.setting?.performance_report?.marketplace?.details &&
                        acl?.setting?.performance_report?.publications?.details && (
                            <SettingsAccordionItem>
                                <SettingsAccordionItemHeading>
                                    <SettingsAccordionItemButton
                                        icon={<img src={require('assets/settings/report.svg').default} alt="" />}
                                    >
                                        {t('Performance Reports')}
                                    </SettingsAccordionItemButton>
                                </SettingsAccordionItemHeading>
                                <SettingsAccordionPanel>
                                    {acl?.setting?.performance_report?.marketplace?.details && (
                                        <SettingsAccordionPanelItem>
                                            <NavLink
                                                className={(navData) =>
                                                    navData.isActive
                                                        ? 'sob-accordion__panel_item active'
                                                        : 'sob-accordion__panel_item'
                                                }
                                                to="/settings/rapportPublication"
                                            >
                                                {t('Publication Reports')}
                                            </NavLink>
                                        </SettingsAccordionPanelItem>
                                    )}
                                    {acl?.setting?.performance_report?.publications?.details && (
                                        <SettingsAccordionPanelItem>
                                            <NavLink
                                                className={(navData) =>
                                                    navData.isActive
                                                        ? 'sob-accordion__panel_item active'
                                                        : 'sob-accordion__panel_item'
                                                }
                                                to="/settings/rapportMarketplace"
                                            >
                                                {t('Marketplace Report')}
                                            </NavLink>
                                        </SettingsAccordionPanelItem>
                                    )}
                                </SettingsAccordionPanel>
                            </SettingsAccordionItem>
                        )}
                    {acl?.setting?.custom_fields?.list && acl?.setting?.custom_fields?.event_fields && (
                        <SettingsAccordionItem>
                            <SettingsAccordionItemHeading>
                                <SettingsAccordionItemButton
                                    icon={<img src={require('assets/settings/event.svg').default} alt="" />}
                                >
                                    {t('Event Settings')}
                                </SettingsAccordionItemButton>
                            </SettingsAccordionItemHeading>
                            <SettingsAccordionPanel>
                                {acl?.setting?.custom_fields?.list && (
                                    <SettingsAccordionPanelItem>
                                        <NavLink
                                            className={(navData) =>
                                                navData.isActive
                                                    ? 'sob-accordion__panel_item active'
                                                    : 'sob-accordion__panel_item'
                                            }
                                            to="/settings/customFields"
                                        >
                                            {t('Custom Fields')}
                                        </NavLink>
                                    </SettingsAccordionPanelItem>
                                )}
                                {acl?.setting?.custom_fields?.event_fields && (
                                    <SettingsAccordionPanelItem>
                                        <NavLink
                                            className={(navData) =>
                                                navData.isActive
                                                    ? 'sob-accordion__panel_item active'
                                                    : 'sob-accordion__panel_item'
                                            }
                                            to="/settings/customReport"
                                        >
                                            {t('Report Customization')}
                                        </NavLink>
                                    </SettingsAccordionPanelItem>
                                )}
                            </SettingsAccordionPanel>
                        </SettingsAccordionItem>
                    )}
                    {acl?.setting?.custom_fields?.list && (
                        <SettingsAccordionItem>
                            <SettingsAccordionItemHeading>
                                <SettingsAccordionItemButton
                                    icon={<img src={require('assets/settings/integration.svg').default} alt="" />}
                                >
                                    {t('API and Integrations')}
                                </SettingsAccordionItemButton>
                            </SettingsAccordionItemHeading>
                            <SettingsAccordionPanel>
                                <SettingsAccordionPanelItem>
                                    <NavLink
                                        className={(navData) =>
                                            navData.isActive
                                                ? 'sob-accordion__panel_item active'
                                                : 'sob-accordion__panel_item'
                                        }
                                        to="/settings/api"
                                    >
                                        API
                                    </NavLink>
                                </SettingsAccordionPanelItem>
                            </SettingsAccordionPanel>
                        </SettingsAccordionItem>
                    )}
                </SettingsAccordion>
                <div className="settings-content">
                    <Outlet />
                </div>
            </div>
        </section>
    );
}

export default Settings;
