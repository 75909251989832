import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Card, SpinnerLoading } from '@sobrus-com/sobrus-design-system-v2';

// import { SpinnerLoading } from '@sobrus-com/sobrus-design-system-v2';
import './FastActionCard.scss';

function FastActionCard({ title, data, total, color, iconSrc, to }) {
    const { t } = useTranslation();

    return (
        <Card
            IconButton={
                <img
                    style={{ display: 'block' }}
                    src={iconSrc || require('../../assets/drugs-icon.svg').default}
                    alt=""
                />
            }
            cardTitle={title}
            className="dashbord-fastAction_Card"
        >
            {/* <div className="dashbord-fastAction_Card-header">
                <img src={iconSrc || require('../../assets/drugs-icon.svg').default} alt="" />
                <h6 className="h6">{title}</h6>
            </div> */}
            {!data ? (
                <SpinnerLoading size={12} loading color="#DF4751" />
            ) : (
                <div className="dashbord-fastAction_Card-content">
                    <span className="dashbord-highlight" style={color && { color }}>
                        {total}
                    </span>
                    <Link to={to || ''}>
                        <Button
                            type="button"
                            // className="dashbord-fastAction_Card-btn"
                            style={color && { color, backgroundColor: `${color}15` }}
                            // color={color}
                            righticon={
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12.655"
                                    height="13.483"
                                    viewBox="0 0 12.655 13.483"
                                >
                                    <g
                                        id="Icon_feather-arrow-right-circle"
                                        data-name="Icon feather-arrow-right-circle"
                                        transform="translate(1 1.414)"
                                    >
                                        <path
                                            id="Path_89"
                                            data-name="Path 89"
                                            d="M18,22.655l5.327-5.327L18,12"
                                            transform="translate(-12.673 -12)"
                                            fill="none"
                                            stroke={color || '#3E79E8'}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                        />
                                        <path
                                            id="Path_90"
                                            data-name="Path 90"
                                            d="M12,18H22.655"
                                            transform="translate(-12 -12.673)"
                                            fill="none"
                                            stroke={color || '#3E79E8'}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                        />
                                    </g>
                                </svg>
                            }
                        >
                            {t('View')}
                        </Button>
                    </Link>
                </div>
            )}
        </Card>
    );
}

export default FastActionCard;
